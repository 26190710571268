import PropTypes from 'prop-types';
import {
    Box,
    IconButton,
    List,
    Stack,
    Popover,
    SvgIcon,
    Tooltip,
    Typography, CircularProgress, useMediaQuery, Fade, Card, Popper
} from '@mui/material';
import React, {useEffect, useState} from "react";
import { useNavigate } from "react-router";
import {useSelector} from "react-redux";
import MessagesIcon from "@untitled-ui/icons-react/build/esm/ArrowRight";
import { produce } from "immer";
import { ChatThreadItem as ThreadItem } from "../../../modules/home/connect/chat/chat-thread-item";
import {useTranslation} from "react-i18next";
import {useIsMobile} from "../../../../hooks/use-is-mobile";
import {APP_SETTING} from "../../../../../setup";
import SmartPopper from "../../../elements/smart-popper";
import DelayedCircularProgress from "../../../elements/delayed-circular-progress";
import OnIcon from "../../../elements/icon";
import {useTheme} from "@mui/system";

export const ChatPopover = ({popover}) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const threads = useSelector((state) => state.messages.threads);
    const { isFlying } = useIsMobile();
    const threadsLoaded = useSelector((state) => state.messages.threadsLoaded);
    const [sortedThreads, setSortedThreads] = useState([]);

    const openChat = () => {
        navigate('/groon/home/chat');
        popover.handleClose();
    }

    useEffect(() => {
        setSortedThreads(produce(threads, draft => {
            draft.sort((a, b) => {
                if ((typeof (a.last_message) !== "undefined") && (typeof (b.last_message) !== "undefined")) {
                    if ((a.last_message !== null) && (b.last_message !== null)) {
                        a = (new Date(a.last_message.created_at)).getTime();
                        b = (new Date(b.last_message.created_at)).getTime();
                        return ((a > b)) ? -1 : 1;
                    }
                }
                return 1;
            });
        }));
    }, [threads]);

    return (
        <Popover
            anchorEl={popover.anchorRef.current}
            anchorOrigin={isFlying ? {
                vertical: 'bottom',
                horizontal: 'left',
            } : {
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={isFlying ? {
                vertical: 'bottom',
                horizontal: 'right',
            } : {
                vertical: 'top',
                horizontal: 'left',
            }}
            disableScrollLock
            onClose={popover.handleClose}
            open={popover.open}
            slotProps={{
                paper: {
                    sx: {borderRadius: theme?.config?.card_radius + "px", width: 275},
                    style: {transform: isFlying ? 'translateX(-15px)' : 'translateY(10px)'}
                },
            }}
        >
            <Stack
                alignItems="center"
                direction="row"
                justifyContent="space-between"
                spacing={2}
                p={2}
            >
                <Typography
                    color="inherit"
                    variant="h6"
                >
                    {t("common.messages")}
                </Typography>
                <Tooltip enterDelay={1000} title={t("common.to_my_messages")} placement="bottom">
                    <IconButton
                        onClick={openChat}
                        size="small"
                        color="inherit"
                    >
                        <SvgIcon>
                            <MessagesIcon/>
                        </SvgIcon>
                    </IconButton>
                </Tooltip>
            </Stack>
            <Box sx={{p: 0}}>
                {sortedThreads.length > 0 ? (
                    <List sx={{overflow: 'auto', maxHeight: 390, p: 1}}>
                        {sortedThreads.map((thread, i) => {
                            return (
                                <ThreadItem
                                    key={'thread_list_' + i}
                                    topbar={true}
                                    onSelect={popover.handleClose}
                                    thread={thread}
                                    active={false}
                                />
                            )
                        })}
                    </List>
                ) : (
                    <div style={{height: 200, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        {threadsLoaded ? (
                            <Stack
                                direction="column"
                                spacing={1}
                                alignItems='center'
                                color='textSecondary'
                            >
                                <OnIcon
                                    iconName="MessageXCircle"
                                    size="large"
                                    sx={{color: theme.palette.text.secondary}}
                                />
                                <Typography variant="subtitle2" color="textSecondary">
                                    {t("core.no_chats")}
                                </Typography>
                            </Stack>
                        ) : (
                            <div style={{
                                height: '200px',
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                                <DelayedCircularProgress size={20} />
                            </div>
                        )}
                    </div>
                )}
            </Box>
        </Popover>
    )

};

ChatPopover.propTypes = {
    popover: PropTypes.object.isRequired,
};
