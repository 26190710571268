import React, { useEffect, useRef, useState } from "react";
import {
    Box,
    Button,
    Card,
    Dialog,
    IconButton,
    Slider,
    Stack,
    Typography,
    TextField,
    Popover,
    Tooltip,
    List,
    Divider,
    Alert,
    CircularProgress,
    Grid2,
    LinearProgress,
} from "@mui/material";
import PropTypes from "prop-types";
import useSocket from "../../hooks/use-socket";
import { useTheme } from "@mui/system";
import { useTranslation } from "react-i18next";
import CytoscapeComponent from "react-cytoscapejs";
import { useNavigate } from "react-router-dom";
import OnIcon from "./icon";
import SmartSelector from "./smart-selector";
import SaveButton from "./save-button";
import useRenderObjects from "../../hooks/data-rendering/use-render-objects";
import {useIsMobile} from "../../hooks/use-is-mobile";
import useOmniaApi from "../../hooks/use-omnia-api";

function DataGraph(props) {
    const {
        kind = null,
        object = null,
        sx = {},
        maxNodes = 50,
        maxDepth = 3,
        height = 400,
        initialActiveObjects = ["core_user", "crm_contact", "pm_projct", "tms_order"],
        ...rest
    } = props;

    const [localKind, setLocalKind] = useState(kind);
    const [localObjectId, setLocalObjectId] = useState(object?.id);
    const [query, setQuery] = useState("");
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({nodes: [], edges: []});
    const [message, setMessage] = useState("preparing");
    const [applying, setApplying] = useState(false);
    const [percentage, setPercentage] = useState(0);
    const theme = useTheme();
    const navigate = useNavigate();
    const { isMobile } = useIsMobile();
    const cyRef = useRef(null);
    const { t } = useTranslation();
    const [fullscreenOpen, setFullscreenOpen] = useState(false);
    const [localMaxNodes, setLocalMaxNodes] = useState(maxNodes);
    const [localMaxDepth, setLocalMaxDepth] = useState(maxDepth);
    const [localMaxComputeSteps, setLocalMaxComputeSteps] = useState(1000);
    const [localSpreading, setLocalSpreading] = useState(1.0);
    const [ignoredNodeIds, setIgnoredNodeIds] = useState([]);
    const [activeObjects, setActiveObjects] = useState(initialActiveObjects);
    const [error, setError] = useState(null);
    const [anchorPosition, setAnchorPosition] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const { get } = useOmniaApi();
    const [clickedNodeData, setClickedNodeData] = useState(null);
    const [loadingClickedNodeObject, setLoadingClickedNodeObject] = useState(false);
    const [clickedNodeObject, setClickedNodeObject] = useState(null);
    const renderObjects = useRenderObjects();
    const objectKindRef = useRef();
    const objectIdRef = useRef();
    const maxNodesRef = useRef();
    const maxDepthRef = useRef();
    const maxComputeStepsRef = useRef();
    const activeObjectsRef = useRef();
    const ignoreNodesRef = useRef();
    const spreadingRef = useRef();
    objectKindRef.current = localKind;
    objectIdRef.current = localObjectId;
    maxNodesRef.current = localMaxNodes;
    maxDepthRef.current = localMaxDepth;
    activeObjectsRef.current = activeObjects;
    ignoreNodesRef.current = ignoredNodeIds;
    maxComputeStepsRef.current = localMaxComputeSteps;
    spreadingRef.current = localSpreading;

    const objectTypes = [
        "core_user",
        "core_usergroup",
        "crm_contact",
        "pm_space",
        "tms_order",
        "tms_offer",
        "tms_invoice",
        "core_kpi",
        "core_task",
        "core_file",
        "crm_event",
        "crm_registrationcontext",
        "ai_aimodel",
        "core_assistant",
        "tms_product",
        "sm_campaign",
        "core_dataset",
        "ai_training",
        "sm_digitalchannel",
        "core_dashboard",
        "core_workflow",
        "pm_board",
        "cms_site",
    ];

    const { sendMessage, isConnected } = useSocket("data-graph", (packet) => {
        if (packet["kind"] === "statusMessage") {
            setMessage(packet["data"]);
        }

        if (packet["kind"] === "statusPercentage") {
            setPercentage(packet["data"]);
        }

        if (packet['kind'] === 'error') {
            setError(packet['data']);
            setLoading(false);
            setApplying(false);
        }

        if (packet["kind"] === "graph") {
            setMessage("drawing-graph");
            setError(null);

            const newGraphData = packet.data;
            newGraphData.nodes.forEach((node) => {
                const typeLabel = t("core.object_names_singular." + node.data.type) || "";
                node.data.combinedLabel = node.data.label + "\n" + typeLabel;
            });

            newGraphData.edges.forEach((edge) => {
                if(edge.data.label.translate){
                    edge.data.label = t("core.edge_labels." + edge.data.label.name);
                    edge.data.fontSize = 24;
                    edge.data.color = theme.palette.text.secondary;
                } else {
                    edge.data.label = edge.data.label.name;
                    edge.data.fontSize = 48;
                    edge.data.width = 8;
                    edge.data.color = theme.palette.text.primary;
                }
            });

            setTimeout(() => {
                setData(newGraphData);
                setLoading(false);
                setApplying(false);
            }, 500);
        }
    });

    const handleNodeClick = (event) => {
        const node = event.target.data();
        if (!node) return;
        if (node?.type === 'centroid') return;

        const { pageX, pageY } = event.originalEvent;
        setAnchorPosition({ top: pageY, left: pageX });
        setAnchorEl(document.body); // We'll anchor the popover to the body
        setClickedNodeData(node);

    };

    const handleNavigateThere = () => {
        if (clickedNodeData?.link) {
            navigate(clickedNodeData.link);
        }
        handleClosePopover();
    };

    const handleIgnoreNode = () => {
        if (!clickedNodeData) {
            handleClosePopover();
            return;
        }

        setIgnoredNodeIds(prev => [...prev, clickedNodeData.id]);

        // Close popover and set applying
        setApplying(true);
        handleClosePopover();
    }

    const handleCenterAroundHere = () => {
        if (!clickedNodeData) {
            handleClosePopover();
            return;
        }

        // Extract the data
        const [kind, id] = clickedNodeData.id.split("-");
        const newKind = kind;
        const newId = id;

        // Update local states
        setLocalKind(newKind);
        setLocalObjectId(newId);

        // Close popover and set applying
        setApplying(true);
        handleClosePopover();
    };

    const handleClosePopover = () => {
        setAnchorEl(null);
        setAnchorPosition(null);
        setTimeout(() => {
            setClickedNodeData(null);
        }, 250);
    };

    const sendFilterUpdate = () => {
        sendMessage({
            kind: "filterUpdate",
            data: {
                object_kind: objectKindRef.current,
                object_id: objectIdRef.current,
                max_nodes: maxNodesRef.current,
                max_depth: maxDepthRef.current,
                max_steps: maxComputeStepsRef.current,
                active_objects: activeObjectsRef.current,
                k_parameter: spreadingRef.current,
                ignored_node_ids: ignoreNodesRef.current,
            },
        });
    };

    const graphStyle = [
        {
            selector: "node",
            style: {
                // Label
                content: "data(combinedLabel)",
                "text-wrap": "wrap",
                "text-max-width": 400,
                "line-height": 1.5,

                // Main shape
                width: "data(weight)",
                height: "data(weight)",
                "background-color": "data(bgColor)",

                // Border
                "border-width": 2,
                "border-color": "data(borderColor)",
                "border-style": "solid",

                // Font
                color: "data(color)",
                "font-size": "data(fontSize)",
                "text-valign": "center",
                "text-halign": "center",
            },
        },
        {
            selector: "edge",
            style: {
                width: "data(width)",
                color: "data(color)",
                "line-color": "data(color)",
                "target-arrow-color": "data(color)",
                "text-rotation": "autorotate",
                "target-arrow-shape": "data(arrowShape)",
                "label": "data(label)",
                "font-size": "data(fontSize)",
                "text-background-color": theme.palette.background.paper,
                "text-background-opacity": 1,
                "text-background-padding": "15px",
                "curve-style": "bezier",
            },
        },
        {
            selector: ".highlight",
            style: {
                "background-color": theme.palette.warning.main,
                "border-color": theme.palette.warning.main,
                color: theme.palette.warning.contrastText,
            },
        },
    ];

    const layout = {
        name: "preset",
    };

    const handleOpenFullscreen = () => {
        setFullscreenOpen(true);
    };

    const handleCloseFullscreen = () => {
        setFullscreenOpen(false);
    };

    const handleActiveObjectsChange = (event) => {
        const {
            target: { value },
        } = event;
        setActiveObjects(typeof value === "string" ? value.split(",") : value);
    };

    const handleReset = () => {
        setIgnoredNodeIds([]);
        setLocalKind(kind);
        setLocalObjectId(object?.id);

        setApplying(true);
    }

    useEffect(() => {
        if (isConnected) {
            // Initial load of the graph
            sendFilterUpdate();
        } else {
            if(!loading){
                setApplying(false);
            }
        }
    }, [isConnected, localKind, localObjectId, ignoredNodeIds]);

    useEffect(() => {
        // Check if the node has endpoint
        if (clickedNodeData?.endpoint && clickedNodeData?.id?.includes('-')) {
            const id = clickedNodeData.id.split("-")[1];
            setLoadingClickedNodeObject(true);
            get(clickedNodeData.endpoint + '/' + id).then(data => {
                setClickedNodeObject(data);
            }).finally(() => {
                setLoadingClickedNodeObject(false);
            })
        }
    }, [JSON.stringify(clickedNodeData)]);

    useEffect(() => {
        if (!cyRef.current) return;
        if (!query) {
            cyRef.current.nodes().removeClass("highlight");
            return;
        }

        const cy = cyRef.current;
        cy.nodes().removeClass("highlight");

        const matchingNodes = cy
            .nodes()
            .filter((node) =>
                node.data("label")?.toLowerCase()?.includes(query.toLowerCase())
            );

        matchingNodes.addClass("highlight");
    }, [query]);

    const graph = (
        <CytoscapeComponent
            elements={CytoscapeComponent.normalizeElements(data)}
            style={{ width: "100%", height: "100%" }}
            stylesheet={graphStyle}
            layout={layout}
            cy={(cy) => {
                cyRef.current = cy;
                cy.on("click", "node", handleNodeClick);
            }}
        />
    );

    return (
        <>
            <Card sx={{ ...{ height: height, position: "relative" }, ...sx }} {...rest}>
                {(error && !fullscreenOpen) && (
                    <Box sx={{ position: "absolute", bottom: 15, left: 15 }}>
                        <Typography variant="overline" color="error">
                            {t('core.analytics.graph_error')}
                        </Typography>
                    </Box>
                )}
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{
                        position: "absolute",
                        p: 2,
                        top: 0,
                        zIndex: 1,
                        left: 0,
                        right: 0,
                    }}
                >
                    <Stack>
                        <Typography variant="h6">{t("common.data_graph")}</Typography>
                        {loading ? (
                            <Typography variant="caption" color="textSecondary">
                                {t('common.loading')}
                            </Typography>
                        ) : !isConnected ? (
                            <Typography variant="caption" color="error">
                                {t("core.analytics.connection_error")}
                            </Typography>
                        ) : (
                            <Typography variant="caption" color="textSecondary">
                                {applying ? (t('common.loading') + ' ' + percentage?.toFixed(0) + '%') : t('common.up_to_date')}
                            </Typography>
                        )}
                    </Stack>
                    <IconButton onClick={handleOpenFullscreen}>
                        <OnIcon iconName="Maximize01" size="small" />
                    </IconButton>
                </Stack>
                <Box id="data-graph-container" sx={{ height: "100%" }}>
                    {loading ? (
                        <Stack
                            direction="row"
                            spacing={2}
                            alignItems="center"
                            justifyContent="center"
                            sx={{ height: "100%", width: "100%", minHeight: 300 }}
                        >
                            <Stack direction="column" alignItems="center" spacing={2}>
                                {/*<OnIcon iconName="BarChartCircle02" size="large" />*/}
                                {/*<Typography variant="h5" color="textPrimary">*/}
                                {/*    {t("core.analytics.kpi_graph." + message)}*/}
                                {/*</Typography>*/}
                                <Box sx={{ width: 300 }}>
                                    <LinearProgress variant="determinate" value={percentage} />
                                </Box>
                            </Stack>
                        </Stack>
                    ) : graph}
                </Box>
            </Card>

            <Popover
                open={Boolean(anchorEl && anchorPosition)}
                anchorReference="anchorPosition"
                anchorPosition={anchorPosition ? { top: anchorPosition.top, left: anchorPosition.left } : undefined}
                onClose={handleClosePopover}
            >
                <Box>
                    {(clickedNodeObject || loadingClickedNodeObject) ? (
                        <>
                            {loadingClickedNodeObject ? (
                                <Stack direction="row" justifyContent="center" alignItems="center" sx={{width: 350, height: 50}}>
                                    <CircularProgress size={22} />
                                </Stack>
                            ) : (
                                <List sx={{p: 0, width: 350}}>
                                    {renderObjects(clickedNodeData?.type, clickedNodeObject ? [clickedNodeObject] : [])}
                                </List>
                            )}
                            <Divider />
                        </>
                    ) : (
                        <Box sx={{ p: 1, width: 300 }}>
                            <Typography variant="h6" sx={{px: 1}}>
                                {clickedNodeData?.label || t("common.actions")}
                            </Typography>
                            <Typography variant="subtitle2" color="textSecondary" sx={{px: 1}}>
                                {t('core.search.' + clickedNodeData?.type)}
                            </Typography>
                        </Box>
                    )}

                    <Stack direction="row" alignItems="center" sx={{p: 1}}>
                        <Tooltip title={t('core.analytics.center_around_here')} placement="bottom">
                            <IconButton color="primary" onClick={handleCenterAroundHere}>
                                <OnIcon iconName="Target02" size="small" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={t('common.hide')} placement="bottom">
                            <IconButton color="primary" onClick={handleIgnoreNode}>
                                <OnIcon iconName="EyeOff" size="small" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={t('core.analytics.navigate_here')} placement="bottom">
                            <IconButton color="primary" onClick={handleNavigateThere}>
                                <OnIcon iconName="NavigationPointer01" size="small" />
                            </IconButton>
                        </Tooltip>
                    </Stack>
                </Box>
            </Popover>

            <Dialog fullScreen open={fullscreenOpen} onClose={handleCloseFullscreen}>
                <Box sx={{ position: "relative", width: "100%", height: "100%" }}>
                    <Stack
                        direction="column"
                        spacing={2}
                        sx={{
                            position: "absolute",
                            bottom: theme.config.card_radius,
                            left: theme.config.card_radius,
                            width: 350,
                            bgcolor: "background.paper",
                            borderRadius: theme.config.card_radius + "px",
                            p: 2,
                            boxShadow: 4,
                            zIndex: 10,
                        }}
                    >
                        {!isConnected && (
                            <Alert severity="error">
                                {t("core.analytics.connection_error")}
                            </Alert>
                        )}
                        {error && (
                            <Alert severity="error">
                                {t("core.analytics.graph_error_text", { error: error })}
                            </Alert>
                        )}
                        <TextField
                            fullWidth
                            label={t("common.search")}
                            value={query}
                            onChange={(e) => setQuery(e.target.value)}
                        />
                        <SmartSelector
                            label={t("core.analytics.object_types")}
                            multiple={true}
                            options={objectTypes?.map((o) => ({ id: o, label: t('core.search.' + o) }))}
                            values={activeObjects}
                            identField="id"
                            labelField="label"
                            onChange={handleActiveObjectsChange}
                        />
                        {!isMobile && (

                            <Grid2 container spacing={2}>
                                <Grid2 size={{ xs: 12, sm: 6 }}>
                                    <Typography color="textPrimary">
                                        {t("core.analytics.max_computation_steps", { count: localMaxComputeSteps })}
                                    </Typography>
                                    <Slider
                                        value={localMaxComputeSteps}
                                        onChange={(e, newVal) => setLocalMaxComputeSteps(newVal)}
                                        step={1}
                                        min={100}
                                        max={5000}
                                    />

                                </Grid2>
                                <Grid2 size={{ xs: 12, sm: 6 }}>
                                    <Typography color="textPrimary">
                                        {t("core.analytics.spreading", { count: localSpreading?.toFixed(2) })}
                                    </Typography>
                                    <Slider
                                        value={localSpreading}
                                        onChange={(e, newVal) => setLocalSpreading(newVal)}
                                        step={0.01}
                                        min={0.1}
                                        max={5}
                                    />
                                </Grid2>
                                <Grid2 size={{ xs: 12, sm: 6 }}>
                                    <Typography>
                                        {t("core.analytics.max_depth", { count: localMaxDepth })}
                                    </Typography>
                                    <Slider
                                        value={localMaxDepth}
                                        onChange={(e, newVal) => setLocalMaxDepth(newVal)}
                                        step={1}
                                        min={1}
                                        max={10}
                                    />
                                </Grid2>
                                <Grid2 size={{ xs: 12, sm: 6 }}>
                                    <Typography color={(data?.nodes?.length >= localMaxNodes) ? "error" : "textPrimary"}>
                                        {t("core.analytics.max_node_count", { count: data?.nodes?.length, of: localMaxNodes })}
                                    </Typography>
                                    <Slider
                                        value={localMaxNodes}
                                        onChange={(e, newVal) => setLocalMaxNodes(newVal)}
                                        step={1}
                                        min={10}
                                        max={500}
                                        color={(data?.nodes?.length >= localMaxNodes) ? "error" : "primary"}
                                    />
                                </Grid2>
                            </Grid2>
                        )}
                        <Stack
                            direction="row"
                            spacing={1}
                            justifyContent="space-between"
                            sx={{ width: "100%" }}
                        >
                            <Stack direction="row" spacing={1}>
                                <Button variant="outlined" onClick={handleCloseFullscreen}>
                                    {t("common.close")}
                                </Button>
                                {((ignoredNodeIds?.length > 0) || (localKind !== kind) || (localObjectId !== object?.id)) && (
                                    <Button variant="outlined" color="error" onClick={handleReset}>
                                        {t("common.reset")}
                                    </Button>
                                )}
                            </Stack>
                            <SaveButton
                                progress={percentage}
                                loading={applying}
                                onClick={() => {
                                    sendFilterUpdate();
                                    setPercentage(0);
                                    setApplying(true);
                                }}
                                label={t("common.apply")}
                            />
                        </Stack>
                    </Stack>
                    <Box sx={{ width: "100%", height: "100%" }}>
                        {loading ? (
                            <Stack
                                direction="row"
                                spacing={2}
                                alignItems="center"
                                justifyContent="center"
                                sx={{ height: "100%", width: "100%", minHeight: 300 }}
                            >
                                <Stack direction="column" alignItems="center" spacing={2}>
                                    {/*<OnIcon iconName="BarChartCircle02" size="large" />*/}
                                    {/*<Typography variant="h5" color="textPrimary">*/}
                                    {/*    {t("core.analytics.kpi_graph." + message)}*/}
                                    {/*</Typography>*/}
                                    <Box sx={{ width: 400 }}>
                                        <LinearProgress variant="determinate" value={percentage} />
                                    </Box>
                                </Stack>
                            </Stack>
                        ) : graph}
                    </Box>
                </Box>
            </Dialog>
        </>
    );
}

DataGraph.propTypes = {
    kind: PropTypes.string,
    object: PropTypes.object,
    maxNodes: PropTypes.number,
    maxDepth: PropTypes.number,
    height: PropTypes.number,
    initialActiveObjects: PropTypes.array,
    sx: PropTypes.object,
};

export default DataGraph;
