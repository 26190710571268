import React, {useCallback, useEffect, useState} from "react";
import {
    Card,
    CardContent,
    Tabs,
    Tab,
    ListItem,
    ListItemText,
    Stack,
    Tooltip,
    IconButton,
    Typography, Divider, Chip
} from "@mui/material";
import useOmniaApi from "../../../../../hooks/use-omnia-api";
import DelayedCircularProgress from "../../../../elements/delayed-circular-progress";
import OverviewList from "../../../../elements/overview-list";
import {useTranslation} from "react-i18next";
import OnIcon from "../../../../elements/icon";
import {useTheme} from "@mui/system";
import {useNotifications} from "../../../../../hooks/use-notifications";

function DataRestore() {
    const { get, post } = useOmniaApi();
    const theme = useTheme();
    const { t } = useTranslation();
    const { notifySuccess } = useNotifications();
    const [inc, setInc] = useState(0);
    const [endpoints, setEndpoints] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentEndpointIndex, setCurrentEndpointIndex] = useState(0);

    const handleRestore = useCallback((itemId) => {
        const currentEndpoint = endpoints?.[currentEndpointIndex] || [];
        if(window.confirm(t('notify.are_you_sure'))){
            post(currentEndpoint.endpoint + '/' + itemId + '/restore_object').then(() => {
                setInc(prev => prev + 1);
                notifySuccess(t);
            })
        }
    }, [currentEndpointIndex]);

    const handleDestroy = useCallback((itemId) => {
        const currentEndpoint = endpoints?.[currentEndpointIndex] || [];
        if(window.confirm(t('notify.are_you_sure'))){
            post(currentEndpoint.endpoint + '/' + itemId + '/fully_destroy').then(() => {
                setInc(prev => prev + 1);
                notifySuccess(t);
            })
        }
    }, [currentEndpointIndex]);

    const currentEndpoint = endpoints?.[currentEndpointIndex] || [];

    const handleTabChange = (event, newValue) => {
        setCurrentEndpointIndex(newValue);
    };

    useEffect(() => {
        setLoading(true);
        get("setup/sharable_endpoints")
            .then((data) => {
                setEndpoints(data);
                if (data && data.length > 0) {
                    setCurrentEndpointIndex(0);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    if (loading) {
        return (
            <div
                style={{
                    height: 300,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}
            >
                <DelayedCircularProgress />
            </div>
        );
    }

    // Guard against empty endpoints
    if (!endpoints || endpoints.length === 0) {
        return (
            <Stack
                direction="column"
                spacing={1}
                sx={{height: 300, width: '100%'}}
                alignItems='center'
                color='textSecondary'
            >
                <OnIcon
                    iconName="SearchRefraction"
                    size="large"
                    sx={{color: theme.palette.text.secondary}}
                />
                <Typography variant="h5" color="textSecondary">
                    {t('mgt.settings.no_sharable_endpoints')}
                </Typography>
            </Stack>
        );
    }

    return (
        <Card>
            <Tabs
                value={currentEndpointIndex}
                onChange={handleTabChange}
                variant="scrollable"
                scrollButtons="auto"
            >
                {endpoints.map((ep, index) => (
                    <Tab key={index} label={ep.model} />
                ))}
            </Tabs>
            <Divider />
            <OverviewList
                endpoint={currentEndpoint.endpoint + '/archived_objects'}
                noResultsIcon='Archive'
                withSearch={true}
                query={{i: inc}}
                renderItem={(item) => (
                    <ListItem
                        key={item.id}
                        secondaryAction={(
                            <Stack direction="row" alignItems="center" spacing={1}>
                                <Tooltip title={t('mgt.settings.restore_object', {name: item?.entity_name || 'Objekt'})}>
                                    <IconButton onClick={() => handleRestore(item.id)}>
                                        <OnIcon iconName={item?.is_hidden ? "Eye" : "Archive"} size="small" />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title={t('common.tooltips.remove_object_tooltip', {name: item?.object_name || 'Objekt'})}>
                                    <IconButton onClick={() => handleDestroy(item.id)}>
                                        <OnIcon iconName="Trash01" size="small" />
                                    </IconButton>
                                </Tooltip>
                            </Stack>
                        )}
                    >
                        <ListItemText
                            primary={(
                                <Stack direction="row" alignItems="center" spacing={2}>
                                    <Typography variant="h6">
                                        {item?.object_name}
                                    </Typography>
                                    <Chip
                                        size="small"
                                        label={item?.is_hidden ? t('common.hidden') : t('common.archived')}
                                        color={item?.is_hidden ? 'default' : 'warning'}
                                    />
                                </Stack>
                            )}
                            secondary={item?.entity_name}
                        />
                    </ListItem>
                )}
            />
        </Card>
    );
}

export default DataRestore;
