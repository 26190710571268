import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Attachment01Icon from '@untitled-ui/icons-react/build/esm/Attachment02';
import {
    Box, CircularProgress,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    OutlinedInput,
    Popover,
    Stack,
    SvgIcon,
    Tooltip,
    Typography
} from '@mui/material';
import debounce from "lodash.debounce";
import { useDropzone } from "react-dropzone";
import { useTheme } from "@mui/system";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { bytesToSize } from "src/omnia/utils/bytesToSize";
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import EmojiIcon from "@mui/icons-material/TagFaces";
import SendIcon from "@untitled-ui/icons-react/build/esm/ArrowUp";
import EmojiPicker from "emoji-picker-react";
import { useIsMobile } from "src/omnia/hooks/use-is-mobile";
import { useTranslation } from "react-i18next";
import OnIcon from "../../../../elements/icon";
import {useDialog} from "../../../../../hooks/use-dialog";
import PromptLibraryDialog from "../../../ai-studio/assistants/prompt-library-dialog";

function CircularProgressWithLabel(props) {
    return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress variant="determinate" {...props} />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography
                    variant="caption"
                    component="div"
                    color="text.secondary"
                >{`${Math.round(props?.value)}%`}</Typography>
            </Box>
        </Box>
    );
}

export const ChatMessageAdd = (props) => {

    const {
        disabled = false,
        onTyping,
        noAttachments = false,
        placeholder = null,
        autoFocus = true,
        size = 'small',
        showPromptLibrary = false,
        initialBody = '',
        onSend,
        onChange,
        customActions = null,
        customStartActions = null,
        smallVersion = false,
        onStopTying,
        uploadStatus = null,
        noPadding = false,
        sx = {},
        ...rest
    } = props;

    const [files, setFiles] = useState([]);
    const [body, setBody] = useState(initialBody);
    const [emojiOpen, setEmojiOpen] = useState(false);
    const [sending, setSending] = useState(false);
    const [typing, setTyping] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const { t } = useTranslation();
    const { isMobile } = useIsMobile();
    const theme = useTheme();
    const softwareName = theme?.config?.software_name || 'AIOS';
    const promptLibrary = useDialog();
    const fileInputRef = useRef(null);
    const loaderTimeoutRef = useRef();
    const textInputRef = useRef();
    const emojiRef = useRef();

    const handleAddEmoji = (emoji) => {
        setBody(prev => prev + emoji.emoji);
        handleStartTyping();
        setEmojiOpen(false);
        handleFocus();
    }

    const handleStopTyping = () => {
        setTyping(false);
    }

    const handleStartTyping = () => {
        setTyping(true);
    }

    const handleFocus = () => {
        if (textInputRef.current) {
            setTimeout(() => {
                textInputRef.current?.focus();
            }, 100);
        }
    }

    const removeFile = (file) => {
        setFiles(prev => prev.filter(f => f.path !== file.path));
    }

    const debouncedChangeHandler = useCallback(debounce(handleStopTyping, 5000), []);

    const handleAttach = useCallback(() => {
        fileInputRef.current?.click();
    }, []);

    const outlinedFixedHeight = {
        small: 45,
        medium: 45,
        large: 45
    }

    const loaderSizes = {
        small: 25,
        medium: 22,
        large: 24
    }

    const handleChange = useCallback((event) => {
        setBody(event.target.value);
        onChange?.(event.target.value);
        debouncedChangeHandler();
        handleStartTyping();
    }, []);

    const handleSend = useCallback(() => {

        // Construct a variable that checks if the message can be sent
        const canSend = !sending && ((body !== null && body !== "") || (files.length > 0));

        // If the message can't be sent, return nothing
        if (!canSend)
            return;

        // Stop typing
        handleStopTyping();

        // Call the send callback
        setSending(true);
        clearTimeout(loaderTimeoutRef.current);
        loaderTimeoutRef.current = setTimeout(() => setShowLoader(true), 1000);
        onSend({ message: body, files: files }).then(() => {
            // Reset the body, files, focus and so on
            setBody('');
            setFiles([]);
            handleFocus();
            if (fileInputRef.current) {
                fileInputRef.current.value = null;
            }
        }).finally(() => {
            clearTimeout(loaderTimeoutRef.current);
            setShowLoader(false);
            setSending(false);
        })

    }, [body, files, onSend]);

    const handlePromptSelect = (prompt) => {
        promptLibrary.handleClose();
        setBody(prompt?.prompt);
        setTimeout(() => {
            handleSend();
        }, 500);
    }

    const handleDrop = useCallback((acceptedFiles) => {
        setFiles((prevFiles) => [...prevFiles].concat(acceptedFiles));
        handleStartTyping();
        fileInputRef.current.value = null;
        handleFocus();
    }, []);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop: handleDrop
    });

    const handleKeyUp = useCallback((event) => {
        if (event.code === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            handleSend();
        }
    }, [handleSend]);

    useEffect(() => {
        if (!disabled && autoFocus) {
            handleFocus();
        }
    }, [disabled, autoFocus]);

    useEffect(() => {
        if (typing) {
            onTyping?.();
        } else {
            onStopTying?.();
        }
    }, [typing]);

    useEffect(() => {
        if(initialBody !== '')
            setBody(prev => prev === '' ? initialBody : prev);
    }, [initialBody]);

    return (
        <Stack
            container {...getRootProps()}
            direction="column"
            spacing={0}
        >
            <Stack
                alignItems="center"
                direction="row"
                spacing={1}
                sx={{ p: noPadding ? 0 : 1.5 }}
            >
                <PromptLibraryDialog
                    open={promptLibrary.open}
                    onClose={promptLibrary.handleClose}
                    onSelect={handlePromptSelect}
                />
                <OutlinedInput
                    disabled={disabled || sending}
                    fullWidth
                    multiline
                    maxRows={16}
                    autoFocus={autoFocus}
                    inputRef={textInputRef}
                    onChange={handleChange}
                    onKeyDown={handleKeyUp}
                    size={size}
                    sx={{ ...sx, ...{ minHeight: outlinedFixedHeight?.[size] }, ...(files?.length > 0 ? {borderBottomRightRadius: 0, borderBottomLeftRadius: 0, mb: 0.5} : {}) }}
                    placeholder={softwareName + ' Message'}
                    value={body}
                    inputProps={{
                        fontSize: 40
                    }}
                    startAdornment={customStartActions}
                    endAdornment={(sending && showLoader) ? (((uploadStatus && uploadStatus < 100) ? <CircularProgressWithLabel value={uploadStatus} size={loaderSizes[size]} /> : <CircularProgress size={loaderSizes[size]} />)) : (
                        <Stack direction="row" alignItems="center" spacing={1}>
                            {showPromptLibrary && (
                                <Tooltip enterDelay={1000} title={t('intelligence.assistants.prompt_library')}>
                                    <IconButton
                                        onClick={promptLibrary.handleOpen}
                                        edge="end"
                                        size="small"
                                    >
                                        <SvgIcon fontSize={(smallVersion || isMobile) ? "small" : "medium"}>
                                            <BookmarkBorderIcon />
                                        </SvgIcon>
                                    </IconButton>
                                </Tooltip>
                            )}
                            {!noAttachments && (
                                <Tooltip enterDelay={1000} title={t('common.buttons.add_attachment')}>
                                    <IconButton
                                        onClick={handleAttach}
                                        disabled={disabled || sending}
                                        edge="end"
                                        size="small"
                                    >
                                        <SvgIcon fontSize={(smallVersion || isMobile) ? "small" : "medium"}>
                                            <Attachment01Icon />
                                        </SvgIcon>
                                    </IconButton>
                                </Tooltip>
                            )}
                            {(!smallVersion && !isMobile) && (
                                <Tooltip enterDelay={1000} title={t('common.tooltips.add_message_emoji')}>
                                    <IconButton
                                        edge="end"
                                        onClick={() => setEmojiOpen(true)}
                                        disabled={disabled || sending}
                                        size="small"
                                        ref={emojiRef}
                                    >
                                        <EmojiIcon />
                                    </IconButton>
                                </Tooltip>
                            )}
                            {customActions}
                            {((body || (files.length > 0)) && isMobile) && (
                                <IconButton
                                    color="primary"
                                    disabled={!(body || files.length > 0) || disabled || sending}
                                    sx={{
                                        backgroundColor: 'primary.main',
                                        color: 'primary.contrastText',
                                        '&:hover': {
                                            backgroundColor: 'primary.dark'
                                        }
                                    }}
                                    size="small"
                                    onClick={handleSend}
                                >
                                    <SvgIcon fontSize="small">
                                        <SendIcon />
                                    </SvgIcon>
                                </IconButton>
                            )}
                        </Stack>
                    )}
                    {...rest}
                />
                <Popover
                    open={emojiOpen}
                    anchorEl={emojiRef.current}
                    onClose={() => setEmojiOpen(false)}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                    style={{ zIndex: 1501 }}
                >
                    <EmojiPicker skinTonesDisabled onEmojiClick={handleAddEmoji} />
                </Popover>
                <input
                    {...getInputProps()}
                    style={{ display: 'none' }}
                    ref={fileInputRef}
                    type="file"
                />
            </Stack>
            {files.length > 0 && (
                <Box mt={0.5}>
                    <List style={{ maxHeight: 200, overflow: 'auto', backgroundColor: theme.palette.background.paper }}>
                        {files.map((file, i) => (
                            <ListItem
                                divider={i < files.length - 1}
                                key={'message-attachment-' + i}
                            >
                                <ListItemIcon>
                                    <FileCopyIcon />
                                </ListItemIcon>
                                <ListItemText
                                    primary={(
                                        <Typography variant="subtitle1">
                                            {file.name}
                                        </Typography>
                                    )}
                                    secondary={bytesToSize(file.size)}
                                />
                                <Tooltip enterDelay={1000} title={t('common.tooltips.remove_attachment')}>
                                    <IconButton
                                        edge="end"
                                        disabled={sending}
                                        onClick={() => {
                                            removeFile(file)
                                        }}
                                    >
                                        <OnIcon iconName="Trash01" size="small" />
                                    </IconButton>
                                </Tooltip>
                            </ListItem>
                        ))}
                    </List>
                </Box>
            )}
        </Stack>
    );
};

ChatMessageAdd.propTypes = {
    disabled: PropTypes.bool,
    sending: PropTypes.bool.isRequired,
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    onTyping: PropTypes.func,
    uploadStatus: PropTypes.number,
    sx: PropTypes.object,
    customActions: PropTypes.node,
    showPromptLibrary: PropTypes.bool,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    onStopTying: PropTypes.func,
    onSend: PropTypes.func,
    smallVersion: PropTypes.bool,
    autoFocus: PropTypes.bool,
    noPadding: PropTypes.bool,
    noAttachments: PropTypes.bool,
};
