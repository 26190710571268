import React, { useEffect, useState } from "react";
import { Fade, Grid, Stack, SvgIcon, Typography, Link, Box } from "@mui/material";
import PropTypes from "prop-types";
import { useTheme } from "@mui/system";
import countToCol from "../../utils/get-column-for-count";
import { useTranslation } from "react-i18next";
import OnIcon from "./icon";
import { useSecurityCheck } from "../../hooks/use-security-check";
import { APP_SETTING } from "../../../setup";
import useGenerateVisibility from "../../hooks/use-generate-visibility";
import { Link as RouterLink } from "react-router-dom";

function SelectionTiles(props) {
    const {
        options,
        onSelect = null,
        selected = null,
        tileHeight = 175,
        paperBackground = true,
        lowestCol = 4,
        lowestMobileCol = 12,
        fixedCol = null,
        iconSize = "large",
        notAvailableWarning = null,
        spacing = 2,
        randomOrder = false,
        keyControl = false,
        delay = 25,
        dataKind = null,
        ...rest
    } = props;

    const theme = useTheme();
    const { hasRights } = useSecurityCheck();
    const { t } = useTranslation();
    const { visible } = useGenerateVisibility(options.length || 0, delay, randomOrder);

    const [focusIndex, setFocusIndex] = useState(-1);
    const [hoveredIndex, setHoveredIndex] = useState(-1);

    const getGridCols = () => {
        const col = countToCol(options.length);
        return fixedCol ? fixedCol : col < lowestCol ? lowestCol : col;
    };

    const getColumnCount = (gridCols) => {
        switch (gridCols) {
            case 1:
                return 12;
            case 2:
                return 6;
            case 3:
                return 4;
            case 4:
                return 3;
            case 6:
                return 2;
            case 12:
                return 1;
            default:
                return 3; // fallback
        }
    };

    useEffect(() => {
        // We define the handler inside useEffect so it can capture state
        const handleKeyDown = (e) => {
            // If you have no options, do nothing.
            if (!options?.length) return;

            let newIndex = focusIndex;
            const gridCols = getColumnCount(getGridCols());

            switch (e.key) {
                case "ArrowRight": {
                    e.preventDefault();
                    newIndex = Math.min(focusIndex + 1, options.length - 1);
                    setFocusIndex(newIndex);
                    setHoveredIndex(-1);
                    break;
                }
                case "ArrowLeft": {
                    e.preventDefault();
                    newIndex = Math.max(focusIndex - 1, 0);
                    setFocusIndex(newIndex);
                    setHoveredIndex(-1);
                    break;
                }
                case "ArrowDown": {
                    e.preventDefault();
                    newIndex = focusIndex + gridCols;
                    if (newIndex >= options.length) {
                        newIndex = options.length - 1;
                    }
                    setFocusIndex(newIndex);
                    setHoveredIndex(-1);
                    break;
                }
                case "ArrowUp": {
                    e.preventDefault();
                    newIndex = focusIndex - gridCols;
                    if (newIndex < 0) {
                        newIndex = 0;
                    }
                    setFocusIndex(newIndex);
                    setHoveredIndex(-1);
                    break;
                }
                case "Enter":
                case " ": {
                    // e.preventDefault() to block page scrolling on Space, etc.
                    e.preventDefault();
                    if (focusIndex >= 0 && focusIndex < options.length) {
                        const option = options[focusIndex];
                        if (option.disabled) {
                            alert(notAvailableWarning || t("common.not_available"));
                        } else {
                            onSelect && onSelect(option);
                        }
                    }
                    break;
                }
                default:
                    break;
            }
        };

        // Attach the listener on mount:
        if(keyControl)
            window.addEventListener("keydown", handleKeyDown, { passive: false });

        // Detach the listener on unmount:
        return () => {
            window.removeEventListener("keydown", handleKeyDown, { passive: false });
        };
    }, [keyControl, focusIndex, options, onSelect, t, notAvailableWarning]);

    const isActiveTile = (idx) => idx === hoveredIndex || idx === focusIndex;

    const renderTile = (option, index) => {
        // Elevated style if active
        const activeStyles =
            option.disabled || !onSelect
                ? {}
                : {
                    transform: "scale(1.03)",
                    boxShadow: theme.shadows[4],
                    // backgroundColor:
                    //     theme.palette.mode === "light"
                    //         ? "background.discrete"
                    //         : "background.discrete",
                };

        return (
            <Stack
                direction="column"
                alignItems="center"
                justifyContent="center"
                sx={{
                    height: tileHeight,
                    width: "100%",
                    border:
                        selected && selected === (option?.value || option?.path)
                            ? "3px solid"
                            : "1px solid",
                    borderColor:
                        selected && selected === (option?.value || option?.path)
                            ? "primary.main"
                            : "divider",
                    borderRadius: `${theme?.config?.card_radius}px`,
                    textAlign: "center",
                    cursor: onSelect ? "pointer" : "default",
                    backgroundColor: paperBackground ? "background.paper" : "transparent",
                    position: "relative",
                    overflow: "hidden",
                    transition: "transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease",

                    // If active, apply highlight
                    ...(isActiveTile(index) && activeStyles),

                    // If tile has background image
                    ...(option.bgImg && {
                        backgroundImage: `url(${option.bgImg})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                    }),

                    // Standard MUI-based :hover for the tile
                    "&:hover": option.disabled ? {} : activeStyles,
                }}
                onMouseEnter={() => {
                    setHoveredIndex(index);
                    setFocusIndex(-1);
                }}
                onMouseLeave={() => {
                    if (hoveredIndex === index) {
                        setHoveredIndex(-1);
                    }
                }}
                onClick={() => {
                    if (option.disabled) {
                        alert(notAvailableWarning || t("common.not_available"));
                    } else {
                        onSelect && onSelect(option);
                    }
                }}
            >
                {/* Optionally overlay for background images */}
                {option.bgImg && (
                    <div
                        style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            backgroundColor: "rgba(0, 0, 0, 0.7)",
                            opacity: 0,
                            transition: "opacity 0.3s ease",
                            zIndex: 1,
                        }}
                    />
                )}
                {/* Content */}
                <div
                    style={{
                        position: "relative",
                        zIndex: 2,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100%",
                        width: "100%",
                        transition: "transform 0.3s ease",
                    }}
                >
                    {option.bgImg ? (
                        <Typography
                            variant="body1"
                            color={option.disabled ? "text.disabled" : "text.primary"}
                            sx={{
                                width: "100%",
                                height: "100%",
                                lineHeight: 1.2,
                                opacity: 0,
                                transition: "opacity 0.3s ease",
                                position: "absolute",
                                textAlign: "center",
                                backgroundColor:
                                    theme.palette.mode === "light"
                                        ? "rgba(255, 255, 255, 0.8)"
                                        : "rgba(0, 0, 0, 0.8)",
                            }}
                        >
                            {/* Potential overlay text */}
                        </Typography>
                    ) : null}

                    {/* Actual tile info */}
                    <Stack
                        sx={{ height: "100%", width: "100%", px: 2 }}
                        direction="column"
                        spacing={1}
                        justifyContent="center"
                        alignItems="center"
                    >
                        {option?.icon && (
                            <SvgIcon
                                fontSize={iconSize}
                                color={option.disabled ? "disabled" : "text.primary"}
                            >
                                {typeof option.icon === "string" ? (
                                    <OnIcon
                                        iconName={option.icon}
                                        sx={{
                                            color: option.disabled
                                                ? theme.palette.text.disabled
                                                : theme.palette.text.primary,
                                        }}
                                    />
                                ) : (
                                    <option.icon />
                                )}
                            </SvgIcon>
                        )}
                        <Typography
                            variant="h6"
                            noWrap
                            color={option.disabled ? "text.disabled" : "text.primary"}
                            sx={{ width: "100%", lineHeight: 1.2 }}
                        >
                            {option.label}
                        </Typography>
                        <Typography
                            variant="body1"
                            color={option.disabled ? "text.disabled" : "text.primary"}
                            sx={{ width: "100%", lineHeight: 1.2 }}
                        >
                            {option.description}
                        </Typography>
                    </Stack>
                </div>
            </Stack>
        );
    };

    const gridCols = getGridCols();

    return (
        <Grid
            container
            spacing={spacing}
            {...rest}
        >
            {options
                .filter((o) => (o?.permissions ? hasRights(o?.permissions) : true))
                .map((option, index) => (
                    <Fade
                        key={index}
                        in={visible[index]}
                        timeout={APP_SETTING.transitionDuration || 300}
                    >
                        <Grid
                            item
                            xs={lowestMobileCol}
                            sm={lowestMobileCol}
                            md={gridCols}
                        >
                            {option?.path ? (
                                <Link
                                    key={index}
                                    component={RouterLink}
                                    underline="none"
                                    to={option.path}
                                    sx={{ width: "100%", display: "block" }}
                                    data-kind={dataKind}
                                    data-id={option?.value || option?.path}
                                >
                                    {renderTile(option, index)}
                                </Link>
                            ) : (
                                <Box
                                    sx={{ width: "100%" }}
                                    data-kind={dataKind}
                                    data-id={option?.value || option?.path}
                                >
                                    {renderTile(option, index)}
                                </Box>
                            )}
                        </Grid>
                    </Fade>
                ))}
        </Grid>
    );
}

SelectionTiles.propTypes = {
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            value: PropTypes.any,
            path: PropTypes.string,
            description: PropTypes.string,
            disabled: PropTypes.bool,
            icon: PropTypes.any,
            bgImg: PropTypes.string,
            permissions: PropTypes.any,
        })
    ).isRequired,
    notAvailableWarning: PropTypes.string,
    iconSize: PropTypes.string,
    dataKind: PropTypes.string,
    onSelect: PropTypes.func,
    paperBackground: PropTypes.bool,
    randomOrder: PropTypes.bool,
    keyControl: PropTypes.bool,
    selected: PropTypes.any,
    lowestCol: PropTypes.number,
    spacing: PropTypes.number,
    lowestMobileCol: PropTypes.number,
    fixedCol: PropTypes.number,
    delay: PropTypes.number,
    tileHeight: PropTypes.number,
};

export default SelectionTiles;
