import React, {useEffect, useState} from "react";
import SettingsItems from "../../../../elements/settings/settings-items";
import useOmniaApi from "../../../../../hooks/use-omnia-api";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";
import { produce } from "immer";
import {CircularProgress} from "@mui/material";

function IntegrationConfig({connector, settings, setSettings}){
    const [fields, setFields] = useState([]);
    const { t } = useTranslation();
    const [loadingStates, setLoadingStates] = useState(true);
    const {get} = useOmniaApi();

    useEffect(() => {

        if(connector){
            setLoadingStates(true);
            get('/core/connectors/' + connector?.id + '/get_config_fields').then(data => {

                const finalFields = produce(data, draft => {
                    if(connector?.authenticator === 'Token Authenticator'){
                        draft = [
                            {
                                type: 'password',
                                name: 'access_token',
                                label: t('mgt.connectors.token'),
                                description: t('mgt.connectors.token_info'),
                                required: true
                            },
                            {
                                type: 'datetime',
                                name: 'access_token_valid_until',
                                label: 'mgt.connectors.expires_at',
                                description: t('mgt.connectors.expires_at_info'),
                                required: false
                            }
                        ].concat(data);
                    }
                    return draft;
                })

                setFields(finalFields);
            }).finally(() => {
                setLoadingStates(false);
            })
        }

    }, [connector]);

    if(loadingStates)
        return (
            <div style={{height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <CircularProgress />
            </div>
        )

    return (
        <SettingsItems
            settings={settings}
            setSettings={setSettings}
            fields={[{
                type: 'text',
                name: 'name',
                label: t('mgt.connectors.integration_name'),
                description: t('mgt.connectors.integration_name_info'),
                required: true
            }].concat(fields)}
        />
    )

}

IntegrationConfig.propTypes = {
    connector: PropTypes.object.isRequired,
    settings: PropTypes.object.isRequired,
    setSettings: PropTypes.func.isRequired,
}

export default IntegrationConfig;