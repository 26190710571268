import React, {useCallback, useMemo, useRef, useState} from "react";
import {CircularProgress, Link, Stack, Tooltip} from "@mui/material";
import UserStatus from "./user-status";
import UserOnlineDot from "../UserOnlineDot";
import {useDispatch, useSelector} from "react-redux";
import PropTypes from "prop-types";
import {updateUserAvatar} from "../../../../store/actions/users-actions";
import {updateOwnAvatar} from "../../../../store/actions/account-actions";
import useApi from "../../../../hooks/use-api";
import {useNotifications} from "../../../../hooks/use-notifications";
import {useTranslation} from "react-i18next";
import OnIcon from "../../../elements/icon";
import Avatar from "@mui/material/Avatar";
import {Link as RouterLink} from "react-router-dom";

function UserHeader({userId, sx = {}, noAvatar = false, avatarSize = 'small', tooltipPlacement = 'bottom', ...rest}){
    const accountUser = useSelector(state => state.account.user);
    const users = useSelector(state => state.users.users);
    const user = useMemo(() => {
        if(users?.filter(u => u.id === userId)?.length > 0)
            return users.filter(u => u.id === userId)[0];
        return null;
    }, [users, userId])
    const isThisUser = accountUser?.id === user?.id;
    const { t } = useTranslation();
    const [avatarIsUploading, setAvatarIsUploading] = useState(false);
    const avatarUploadRef = useRef(null);
    const {put} = useApi();
    const { notifySuccess } = useNotifications();
    const dispatch = useDispatch();

    const handleAvatarUpload = () => {
        setAvatarIsUploading(true);
        put('/core/me/change_avatar', {
            file: avatarUploadRef.current.files[0]
        }).then(avatar => {
            dispatch(updateUserAvatar(user, avatar));
            dispatch(updateOwnAvatar(avatar));
            notifySuccess(t);
        }).finally(() => {
            setAvatarIsUploading(false)
        });
    }

    const handleChangeAvatar = useCallback(() => {
        if(!isThisUser)
            return;
        avatarUploadRef.current.click();
    }, [isThisUser])

    return (
        <Stack direction="row" alignItems="center" spacing={1} sx={{...{width: '100%'}, ...sx}} {...rest}>
            {!noAvatar && (
                <Tooltip placement={tooltipPlacement} title={isThisUser ? t('core.click_to_change_image') : null}>
                    <Avatar
                        src={!avatarIsUploading && (user.avatar?.view || null)}
                        size={avatarSize}
                        sx={isThisUser ? {cursor: 'pointer'} : {}}
                        onClick={handleChangeAvatar}
                    >
                        {avatarIsUploading ? <CircularProgress size={20}/> : <OnIcon iconName="User01"/>}
                    </Avatar>
                </Tooltip>
            )}
            <Stack sx={{width: '100%'}}>
                <Stack direction="row" alignItems="center" spacing={1}>
                    <Link
                        underline="hover"
                        variant="h6"
                        color="inherit"
                        to={'/groon/home/profile/' + user?.id}
                        component={RouterLink}
                    >
                        {user?.name}
                    </Link>

                    <UserOnlineDot userId={user?.id} dotOnly/>
                </Stack>
                <UserStatus
                    user={user}
                    withUntil={isThisUser}
                    color="text.secondary"
                    variant="body2"
                />
            </Stack>
            <input type="file" style={{display: "none"}} ref={avatarUploadRef} onChange={handleAvatarUpload}/>
        </Stack>
    )
}

UserHeader.propTypes = {
    userId: PropTypes.number.isRequired,
    sx: PropTypes.object,
    noAvatar: PropTypes.bool,
    avatarSize: PropTypes.string,
    tooltipPlacement: PropTypes.string,
}

export default UserHeader;