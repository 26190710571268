import PropTypes from 'prop-types';
import {Avatar, avatarClasses, AvatarGroup, Box, Chip, Stack, SvgIcon, Typography} from '@mui/material';
import moment from "moment";
import {useDispatch, useSelector} from "react-redux";
import {useCreateSrc} from "../../../../../hooks/use-create-src";
import React, {useRef} from "react";
import {useTheme} from "@mui/system";
import UserOnlineDot from "../../UserOnlineDot";
import {useNavigate} from "react-router";
import {useIsMobile} from "../../../../../hooks/use-is-mobile";
import useApi from "../../../../../hooks/use-api";
import {
    markThreadAsSeen,
    openMiniThread,
    updateAssistantOpen
} from "../../../../../store/actions/messages-actions";
import {useLocation} from "react-router-dom";
import removeHtmlTags from "../../../../../utils/remove-html-tags";
import OnIcon from "../../../../elements/icon";
import {useTranslation} from "react-i18next";

const getLastMessage = (thread) => {
    return thread.last_message;
};

const getRecipients = (participants, userId) => {
    return participants.filter((participant) => participant.id !== userId);
};

const getDisplayName = (recipients, thread) => {
    if(thread.is_group_chat)
        return thread.title;

    return recipients
        .map((participant) => participant.name)
        .join(', ');
};

const getDisplayContent = (userId, lastMessage) => {
    if (!lastMessage) {
        return '';
    }

    const author = lastMessage.sender?.id === userId ? 'Ich: ' : '';
    const message = lastMessage.body === '' ? ((lastMessage?.files?.length === 1) ? 'Hat eine Datei gesendet' : `Hat ${lastMessage?.files?.length} Dateien gesendet`) : lastMessage.body;

    return `${author}${message}`;
};

const getLastActivity = (lastMessage) => {
    if (!lastMessage) {
        return null;
    }

    return moment(lastMessage.created_at).fromNow();

    // return formatDistanceStrict(lastMessage.created_at, new Date(), {
    //   addSuffix: false,
    //   locale: 'DE'
    // });
};

export const ChatThreadItem = (props) => {

    const {active = false, thread, topbar, onSelect, ...other} = props;
    const theme = useTheme();
    const { t } = useTranslation();
    const user = useSelector((state) => state.account.user);
    const recipients = getRecipients(thread.users || [], user.id);
    const lastMessage = getLastMessage(thread);
    const lastActivity = getLastActivity(lastMessage);
    const { get } = useApi();
    const dispatch = useDispatch();
    const getSrc = useCreateSrc();
    const displayName = getDisplayName(recipients, thread);
    const displayContent = getDisplayContent(user?.id, lastMessage);
    const isUnread = thread.unread_count && thread.unread_count > 0;
    const threadRef = useRef();
    threadRef.current = thread;

    const handleClick = () => {

        if(topbar){
            dispatch(openMiniThread(threadRef.current));
            dispatch(updateAssistantOpen(false));
        }

        if (onSelect)
            onSelect();

        markAsRead();
    }

    const markAsRead = () => {
        // Actually call mark thread as seen
        get('connect/threads/' + threadRef.current.id + '/mark_seen');
        // dispatch mark thread as seen
        dispatch(markThreadAsSeen(threadRef.current.id));
    }

    return (
        <Stack
            component="li"
            direction="row"
            onClick={handleClick}
            alignItems="center"
            spacing={1.5}
            sx={{
                borderRadius: '10px',
                cursor: 'pointer',
                p: 1,
                '&:hover': {
                    backgroundColor: 'action.hover'
                },
                ...(active && {
                    backgroundColor: 'action.hover'
                })
            }}
            {...other}
        >
            <div>
                {thread.is_group_chat ? (
                    <AvatarGroup spacing="small">
                        <div style={{
                            width: 35,
                            height: 35,
                            display: 'flex',
                            borderRadius: 20,
                            marginRight: 0,
                            justifyContent: 'center',
                            backgroundColor: theme.palette.primary.main,
                            alignItems: 'center',
                        }}>
                            <SvgIcon fontSize="small" style={{
                                width: 20,
                                height: 20,
                                color: 'white'
                            }}>
                                <OnIcon iconName={thread?.icon} />
                            </SvgIcon>
                        </div>
                    </AvatarGroup>
                ) : (
                    <AvatarGroup
                        spacing="small"
                        max={2}
                        sx={{
                            [`& .${avatarClasses.root}`]: {
                                height: 35,
                                width: 35
                            }
                        }}
                    >
                        {recipients.map((recipient) => (
                            <Avatar
                                key={recipient.id}
                                src={getSrc(recipient.avatar) || null}
                            />
                        ))}
                    </AvatarGroup>
                )}
            </div>
            <Box
                sx={{
                    flexGrow: 1,
                    overflow: 'hidden'
                }}
            >
                <Stack direction="row" spacing={1} alignItems="center">
                    {thread.muted && (
                        <Typography variant="caption">
                            ({t('core.muted')})
                        </Typography>
                    )}
                    <Typography
                        noWrap
                        variant="subtitle2"
                    >
                        {displayName}
                    </Typography>
                    {recipients.length === 1 && (
                        <UserOnlineDot
                            userId={recipients[0]?.id}
                            dotOnly={true}
                        />
                    )}
                </Stack>
                <Stack
                    alignItems="center"
                    direction="row"
                    spacing={1}
                >
                    <Typography
                        color="text.secondary"
                        noWrap
                        sx={{flexGrow: 1}}
                        variant="subtitle2"
                    >
                        {removeHtmlTags(displayContent)}
                    </Typography>
                </Stack>
            </Box>
            {isUnread ? (
                <Chip
                    label={thread.unread_count}
                    color="primary"
                    size="small"
                    // style={{position: 'absolute', right: 7, top: '50%', transform: 'translateY(-50%)'}}
                />
            ) : lastActivity ? (
                    <Typography
                        color="text.secondary"
                        sx={{whiteSpace: 'nowrap'}}
                        variant="caption"
                    >
                        {lastActivity}
                    </Typography>
                ) : (
                <Typography
                    color="text.secondary"
                    sx={{whiteSpace: 'nowrap'}}
                    variant="caption"
                >
                    Keine Nachrichten
                </Typography>
            )}
        </Stack>
    );
};

ChatThreadItem.propTypes = {
    active: PropTypes.bool,
    onSelect: PropTypes.func,
    thread: PropTypes.object.isRequired
};
