import React, { useRef, useState } from "react";
import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormHelperText,
    Grid2,
    IconButton,
    InputAdornment,
    MenuItem,
    OutlinedInput,
    Popover,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import { useTranslation } from "react-i18next";
import ClickableInput from "../../../elements/clickable-input";
import { useDialog } from "../../../../hooks/use-dialog";
import {useDispatch, useSelector} from "react-redux";
import OnIcon from "../../../elements/icon";
import { useFormik } from "formik";
import * as Yup from "yup";
import EmojiPicker from "emoji-picker-react";
import SaveButton from "../../../elements/save-button";
import { DateTimePicker } from "@mui/x-date-pickers-pro";
import { useTheme } from "@mui/system";
import PropTypes from "prop-types";
import useOmniaApi from "../../../../hooks/use-omnia-api";
import {updateMe} from "../../../../store/actions/account-actions";
import moment from "moment/moment";

function UserStatusDialog({ open, onClose }) {
    const { t } = useTranslation();
    const user = useSelector((state) => state.account.user);
    const emojiOpen = useDialog();
    const emojiRef = useRef();
    const dispatch = useDispatch();
    const theme = useTheme();
    const { post } = useOmniaApi();
    const statusUntil = moment(user?.status_until);
    const now = moment();
    const isValid = ((user?.status_until === null) || (user?.status_until && statusUntil.isAfter(now))) && (user?.status_text !== '') && (user?.status_text !== null);
    const [ showSuggestions, setShowSuggestions ] = useState(!user?.status_text || !isValid);

    const suggestions = [
        { text: t('core.user_status.in_a_meeting'), emoji: "🤝", until: "30m" },
        { text: t('core.user_status.focusing'), emoji: "🧘", until: "1h" },
        { text: t('core.user_status.sick'), emoji: "🤒", until: "today" },
        { text: t('core.user_status.vacation'), emoji: "🌴", until: "week" }
    ];

    const handleSubmit = (values, helpers) => {

        post('core/me/update_data', {...user, ...values}).then(updatedUser => {
            dispatch(updateMe(updatedUser));
            onClose?.();
        }).finally(() => {
            helpers.setSubmitting(false);
        })

    };

    const formik = useFormik({
        initialValues: {
            status_emoji: isValid ? user.status_emoji : null,
            status_text: isValid ? user.status_text : "",
            status_until: isValid ? user.status_until : null,
            status_until_setting: isValid ? user.status_until_setting : "never"
        },
        validationSchema: Yup.object().shape({
            status_emoji: Yup.string()
                .max(10, t("form.max_length", { characters: 10 }))
                .nullable(),
            status_text: Yup.string()
                .max(20, t("form.max_length", { characters: 20 }))
                .nullable(),
            status_until: Yup.date().nullable()
        }),
        onSubmit: handleSubmit
    });

    const setUntilToday2359 = () => {
        const now = new Date();
        now.setHours(23, 59, 0, 0);
        return now;
    };

    const setUntilThisSunday2359 = () => {
        const now = new Date();
        // getDay() => 0 is Sunday, 6 is Saturday
        const day = now.getDay();
        // If day=0 => Sunday => go 7 days ahead if you want next Sunday
        // or 0 days if you want "this Sunday" in the current week
        // Adjust to your preference:
        const diff = day === 0 ? 0 : 7 - day;
        now.setDate(now.getDate() + diff);
        now.setHours(23, 59, 0, 0);
        return now;
    };

    const handleStatusUntilOptionChange = (event) => {
        const chosen = event.target.value;
        formik.setFieldValue("status_until_setting", chosen);

        switch (chosen) {
            case "never":
                formik.setFieldValue("status_until", null);
                break;
            case "30m": {
                const d = new Date();
                d.setMinutes(d.getMinutes() + 30);
                formik.setFieldValue("status_until", d);
                break;
            }
            case "1h": {
                const d = new Date();
                d.setHours(d.getHours() + 1);
                formik.setFieldValue("status_until", d);
                break;
            }
            case "4h": {
                const d = new Date();
                d.setHours(d.getHours() + 4);
                formik.setFieldValue("status_until", d);
                break;
            }
            case "today":
                formik.setFieldValue("status_until", setUntilToday2359());
                break;
            case "week":
                formik.setFieldValue("status_until", setUntilThisSunday2359());
                break;
            case "custom":
                // If user picks custom, we let them pick a time in the DateTimePicker
                // If they previously had no date, you might want to default to `new Date()`.
                if (!formik.values.status_until) {
                    formik.setFieldValue("status_until", new Date());
                }
                break;
            default:
                break;
        }
    };

    const handleAddEmoji = (emojiObj) => {
        formik.setFieldValue("status_emoji", emojiObj.emoji);
        emojiOpen.handleClose();
    };

    const handleRemove = () => {
        formik.setFieldValue("status_emoji", null);
        formik.setFieldValue("status_text", "");
        formik.setFieldValue("status_until", null);
        formik.setFieldValue("status_until_setting", "never");
        setShowSuggestions(true);
    };

    const handleCloseCustom = () => {
        // For convenience, revert to "never" or your last known non-custom choice
        formik.setFieldValue("status_until_setting", "never");
        formik.setFieldValue("status_until", null);
    };

    const handleSuggestionClick = (sug) => {
        formik.setFieldValue("status_text", sug.text);
        formik.setFieldValue("status_emoji", sug.emoji);
        if(sug.until === "today") {
            formik.setFieldValue("status_until", setUntilToday2359());
        }
        if(sug.until === "week") {
            formik.setFieldValue("status_until", setUntilThisSunday2359());
        }
        if(sug.until === "1h") {
            const d = new Date();
            d.setHours(d.getHours() + 1);
            formik.setFieldValue("status_until", d);
        }
        if(sug.until === "4h") {
            const d = new Date();
            d.setHours(d.getHours() + 4);
            formik.setFieldValue("status_until", d);
        }
        if(sug.until === "30m"){
            const d = new Date();
            d.setMinutes(d.getMinutes() + 30);
            formik.setFieldValue("status_until", d);
        }
        formik.setFieldValue("status_until_setting", sug.until);
        setShowSuggestions(false);
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth={true}
            maxWidth="sm"
        >
            <DialogTitle>
                <Typography variant="h4" color="textPrimary">
                    {t("core.set_status")}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Grid2 container spacing={2} sx={{ mt: 2 }}>
                    <Grid2 item size={{xs: 12}}>
                        <Stack direction="row" alignItems="center" spacing={2}>
                            <ClickableInput
                                onClick={emojiOpen.handleOpen}
                                ref={emojiRef}
                                sx={{
                                    height: 45,
                                    width: 45,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center"
                                }}
                            >
                                {formik.values.status_emoji ? (
                                    <Typography sx={{ fontSize: 24 }}>
                                        {formik.values.status_emoji}
                                    </Typography>
                                ) : (
                                    <OnIcon
                                        iconName="FaceHappy"
                                        sx={{ color: theme.palette.text.secondary }}
                                    />
                                )}
                            </ClickableInput>

                            <OutlinedInput
                                fullWidth
                                placeholder={t("common.other_placeholder")}
                                name="status_text"
                                size="small"
                                value={formik.values.status_text}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={
                                    Boolean(
                                        formik.touched.status_text && formik.errors.status_text
                                    )
                                }
                                endAdornment={
                                    (formik.values.status_text || formik.values.status_emoji) && (
                                        <InputAdornment position="end">
                                            <IconButton size="small" onClick={handleRemove}>
                                                <OnIcon iconName="XClose" />
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }
                                sx={{ height: 45 }}
                            />
                        </Stack>
                        <Popover
                            open={emojiOpen.open}
                            anchorEl={emojiRef.current}
                            onClose={emojiOpen.handleClose}
                            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                            transformOrigin={{ vertical: "top", horizontal: "center" }}
                        >
                            <EmojiPicker skinTonesDisabled onEmojiClick={handleAddEmoji} />
                        </Popover>
                    </Grid2>
                    <Grid2 item size={{xs: 12}}>
                        {formik.values.status_until_setting !== "custom" ? (
                            <TextField
                                select
                                fullWidth
                                size="small"
                                label={t("core.remove_status_after")}
                                value={formik.values.status_until_setting}
                                onChange={handleStatusUntilOptionChange}
                            >
                                <MenuItem value="never">{t("common.never")}</MenuItem>
                                <MenuItem value="30m">{t("common.30m")}</MenuItem>
                                <MenuItem value="1h">{t("common.1h")}</MenuItem>
                                <MenuItem value="4h">{t("common.4h")}</MenuItem>
                                <MenuItem value="today">{t("common.today")}</MenuItem>
                                <MenuItem value="week">{t("common.this_week")}</MenuItem>
                                <MenuItem value="custom">{t("common.custom")}</MenuItem>
                            </TextField>
                        ) : (
                            <Stack direction="row" alignItems="center" spacing={1}>
                                <DateTimePicker
                                    label={t("core.remove_status_after")}
                                    value={formik.values.status_until}
                                    yearsOrder="desc"
                                    format="dd.MM.yyyy HH:mm"
                                    ampm={false}
                                    slotProps={{
                                        textField: { fullWidth: true, size: "small" }
                                    }}
                                    minDateTime={new Date()}
                                    onChange={(val) => {
                                        formik.setFieldValue("status_until", val);
                                    }}
                                />
                                {/* xClose button to revert to the select */}
                                <IconButton onClick={handleCloseCustom}>
                                    <OnIcon iconName="XClose" />
                                </IconButton>
                            </Stack>
                        )}
                        {Boolean(formik.touched.status_until && formik.errors.status_until) && (
                            <Box mt={1}>
                                <FormHelperText error>
                                    {formik.errors.status_until}
                                </FormHelperText>
                            </Box>
                        )}
                    </Grid2>
                    {showSuggestions && (
                        <>
                            <Grid2 item size={{xs: 12}} sx={{mt: 2}}>
                                <Typography variant="body1" color="textPrimary">
                                    {t('common.recommendations')}
                                </Typography>
                            </Grid2>
                            {suggestions.map((sug) => (
                                <Grid2 item size={{xs: 12, sm: 6}} key={sug.text}>
                                    <ClickableInput
                                        key={sug.text}
                                        onClick={() => handleSuggestionClick(sug)}
                                        sx={{
                                            px: 2,
                                            py: 1,
                                            border: "1px solid",
                                            borderColor: theme.palette.divider,
                                            borderRadius: 1,
                                            cursor: "pointer"
                                        }}
                                    >
                                        <Stack direction="row" alignItems="center" spacing={1}>
                                            <Typography sx={{ fontSize: 20 }}>{sug.emoji}</Typography>
                                            <Stack>
                                                <Typography variant="body2">{sug.text}</Typography>
                                                <Typography variant="caption" color="textSecondary">{t('common.' + sug.until)}</Typography>
                                            </Stack>
                                        </Stack>
                                    </ClickableInput>
                                </Grid2>
                            ))}
                        </>
                    )}
                </Grid2>
            </DialogContent>
            <DialogActions>
                <SaveButton label={t('common.save')} formik={formik} />
            </DialogActions>
        </Dialog>
    );
}

UserStatusDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func
};

export default UserStatusDialog;
