import React from "react";
import PropTypes from "prop-types"
import {Box, Card} from "@mui/material";
import useRenderObjects from "../../../../hooks/data-rendering/use-render-objects";

function ShortTermMemoryGroup({objectKind, memories, ...rest}){
    const renderObjects = useRenderObjects();

    return (
        <Box {...rest}>
            <Card>
                {renderObjects(objectKind, memories)}
            </Card>
        </Box>
    )
}

ShortTermMemoryGroup.propTypes = {
    memories: PropTypes.array.isRequired,
    objectKind: PropTypes.string.isRequired,
}

export default ShortTermMemoryGroup;