import React, {useCallback, useEffect, useRef, useMemo, useState} from "react";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import {
    Box, Button,
    Chip,
    Dialog,
    DialogContent,
    Divider,
    InputAdornment,
    List,
    ListItem,
    ListItemText,
    OutlinedInput,
    Stack,
    Tooltip,
    Skeleton, Typography,
} from "@mui/material";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";
import debounce from "lodash.debounce";
import useOmniaApi from "../../../hooks/use-omnia-api";
import {useNavigate} from "react-router-dom";
import OnIcon from "../icon";
import {coreAppsConfig} from "../../../config";
import {useSecurityCheck} from "../../../hooks/use-security-check";
import useRenderObjects from "../../../hooks/data-rendering/use-render-objects";
import useGroonSettings from "../../../hooks/use-groon-settings";
import {APP_SETTING} from "../../../../setup";
import {useTheme} from "@mui/system";
import {useDispatch, useSelector} from "react-redux";
import {registerSearchResults} from "../../../store/actions/account-actions";
import NoResults from "../no-results";

function longestCommonSubstringLength(str1, str2) {
    let max = 0;
    const len1 = str1.length;
    const len2 = str2.length;
    const dp = Array.from({length: len1 + 1}, () => new Array(len2 + 1).fill(0));

    for (let i = 1; i <= len1; i++) {
        for (let j = 1; j <= len2; j++) {
            if (str1[i - 1] === str2[j - 1]) {
                dp[i][j] = dp[i - 1][j - 1] + 1;
                max = Math.max(max, dp[i][j]);
            }
        }
    }
    return max;
}

function findBestBufferKeyMatch(targetKey, buffer) {
    const allKeys = Object.keys(buffer || {});
    if (!allKeys.length) return null;

    let bestKey = null;
    let bestScore = 0;

    for (const candidateKey of allKeys) {
        const score = longestCommonSubstringLength(targetKey, candidateKey);
        if (score > bestScore) {
            bestScore = score;
            bestKey = candidateKey;
        }
    }

    // If you want a minimum threshold, you can set it here
    // e.g. if (bestScore < 3) return null;
    return bestKey;
}

function SearchResultsSkeleton() {
    const theme = useTheme();
    return (
        <Box sx={{p: 2}}>
            {/* Some skeleton "lines" */}
            {[...Array(3)].map((_, idx) => (
                <Stack key={idx} direction="row" alignItems="center" spacing={2} sx={{mb: 2}}>
                    <Skeleton variant="circular" width={40} height={40} />
                    <Skeleton variant="text" width="70%" />
                </Stack>
            ))}

            {/* Three cards in a row */}
            <Stack direction="row" spacing={2} sx={{mb: 3}}>
                {[...Array(3)].map((_, idx) => (
                    <Skeleton key={idx} variant="rectangular" width="100%" height={100} sx={{borderRadius: theme?.config?.card_radius + 'px'}} />
                ))}
            </Stack>

            {/* Another different form below */}
            <Skeleton variant="rectangular" width="100%" height={60} sx={{borderRadius: theme?.config?.card_radius + 'px'}} />
        </Box>
    );
}

function NoSearchResults () {
    const theme = useTheme();
    const { t } = useTranslation();

    return (
        <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            justifyContent='center'
            sx={{width: '100%', height: 300}}
        >
            <NoResults
                primary={t('common.sst_no_results')}
                icon="FileSearch01"
            />
        </Stack>
    )
}

function SearchDialog({popover, sx = {}}) {
    const {t} = useTranslation();
    const [body, setBody] = useState('');
    const {get, post} = useOmniaApi({autoError: false});
    const inputEl = useRef(null);
    const [currentlySelected, setCurrentlySelected] = useState(null);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [activeSearchFilters, setActiveSearchFilters] = useState([]);
    const [disabledSearchFilters, setDisabledSearchFilters] = useState([]);
    const {hasRights} = useSecurityCheck();
    const dispatch = useDispatch();
    const bufferedResults = useSelector(state => state.account.searchResults);
    const [isLoading, setLoading] = useState(false);
    const navigate = useNavigate();
    const theme = useTheme();
    const softwareName = theme?.config?.software_name || 'AIOS';
    const textInputRef = useRef(null);
    const requestIdRef = useRef(0);
    const listRef = useRef(null);
    const disabledFiltersRef = useRef();
    const renderObjects = useRenderObjects(currentlySelected, popover.handleClose);
    textInputRef.current = body;
    disabledFiltersRef.current = disabledSearchFilters;

    const configurationSettings = useGroonSettings();

    const settingsRoutes = useMemo(() => {
        return configurationSettings?.flatMap(obj => obj.settings)?.map(i => {
            return {
                title: t(i?.label),
                icon: i?.icon,
                path: '/groon/home/settings#' + i?.value,
                permissions: i?.rights || [],
            };
        });
    }, [t, configurationSettings]);

    const specialRoutes = useMemo(() => {
        return [
            // Sidebar Special Entries
            {
                title: t('layout.connections.contexts'),
                icon: 'AlignHorizontalCentre01',
                path: '/groon/connections/contexts',
                permissions: ['crm_contexts']
            },
            {
                title: t('layout.marketing.campaigns'),
                icon: 'Announcement01',
                path: '/groon/marketing/campaigns',
                permissions: ['performance_marketing']
            },

            // Account Menu
            {
                title: t('core.chat'),
                icon: 'MessageChatSquare',
                path: '/groon/home/chat',
                permissions: ['social_intranet']
            },
            {
                title: t('layout.assistants'),
                icon: 'MessageSmileCircle',
                path: '/groon/home/assistants',
                permissions: []
            },
            {
                title: t('layout.profile'),
                icon: 'User01',
                path: '/groon/home/profile',
                permissions: ['social_intranet']
            },
            {
                title: t('attributes.organization'),
                icon: 'Building07',
                path: '/groon/home/organization',
                permissions: ['manage_organization']
            },
            {
                title: t('common.themes'),
                icon: 'Brush03',
                path: '/groon/home/themes',
                permissions: ['theme_management']
            },
        ].concat(settingsRoutes);
    }, [t, settingsRoutes]);

    const transformNavigationData = useCallback((navigationConfig) => {
        const result = [];
        const seenPaths = new Set();

        // Recursive function to process each navigation item.
        const traverse = (item) => {
            if (item.title && item.path) {
                if (!seenPaths.has(item.path)) {
                    seenPaths.add(item.path);
                    result.push({
                        title: t(item.title),
                        icon: item.icon || 'Link02',
                        path: item.path,
                        disabled: item?.disabled || false,
                        permissions: Array.isArray(item.permissions) ? item.permissions : []
                    });
                }
            }
            if (Array.isArray(item.items)) {
                item.items.forEach(child => traverse(child));
            }
        };

        if (Array.isArray(navigationConfig)) {
            navigationConfig.forEach(traverse);
        } else {
            Object.keys(navigationConfig).forEach(key => traverse(navigationConfig[key]));
        }

        return result;
    }, [t]);

    const additionalApps = Object.values(APP_SETTING?.navigation || {});

    const allSections = useMemo(() => {
        return transformNavigationData({...coreAppsConfig, ...additionalApps})
            .concat(additionalApps)
            .concat(specialRoutes)
            ?.filter(p => (p?.path || null) !== null);
    }, [transformNavigationData, specialRoutes]);

    const cacheKey = useMemo(() => {
        const filters = activeSearchFilters.filter(item => !disabledSearchFilters.includes(item));
        return body + '_' + JSON.stringify(filters).replace(' ', '');
    }, [body, activeSearchFilters, disabledSearchFilters]);

    const searchResults = useMemo(() => {
        if (!bufferedResults || !cacheKey) return {};

        // 1) exact match?
        const directHit = bufferedResults[cacheKey];
        if (directHit) {
            return directHit;
        }

        // 2) fallback: find best substring match
        const bestKey = findBestBufferKeyMatch(cacheKey, bufferedResults);
        if (bestKey) {
            return bufferedResults[bestKey] || {};
        }
        return {};
    }, [bufferedResults, cacheKey]);

    const pathHits = useMemo(() => {
        return allSections?.filter(path => {
            const rightsOkay = hasRights(path?.permissions || []);
            const titleHit = path?.title?.toLowerCase().includes(body.toLowerCase());
            return rightsOkay && titleHit;
        });
    }, [allSections, body]);

    const handleSearch = useCallback(() => {
        if (!textInputRef.current) return;

        const currentRequestId = ++requestIdRef.current;
        setLoading(true);

        get('setup/search', {
            search: textInputRef.current,
            disabled_object_filters: disabledFiltersRef.current
        })
            .then(response => {
                // Only apply if this is the latest request
                if (currentRequestId === requestIdRef.current) {
                    const searchActive = response?.active_object_filters || [];
                    const searchDisabled = response?.disabled_object_filters || [];
                    setActiveSearchFilters(searchActive.concat(searchDisabled));

                    const key = response?.query + '_' + JSON.stringify(searchActive).replace(' ', '');
                    dispatch(registerSearchResults(key, response?.results));
                }
            })
            .finally(() => {
                if (currentRequestId === requestIdRef.current) {
                    setLoading(false);
                }
            });
    }, [dispatch]);

    const toggleDisableFilter = (filter) => {
        setDisabledSearchFilters(prev => {
            // Check if it already exists
            if (prev.includes(filter)) {
                return prev.filter(f => f !== filter);
            } else {
                return [...prev, filter];
            }
        });
    };

    const debouncedChangeHandler = useCallback(debounce(handleSearch, 1000), [handleSearch]);

    const handleChange = useCallback((event) => {
        setBody(event.target.value);
        if (event.target.value === '') {
            setActiveSearchFilters([]);
            setDisabledSearchFilters([]);
        }
    }, []);

    const handleNavigate = (link, newTab = false) => {
        popover.handleClose();
        if (newTab) {
            window.open(link, '_blank', 'noopener,noreferrer');
        } else {
            navigate(link);
        }
    };

    const storeChosenSearchResult = (objectKind, objectId) => {
        post('setup/search_result', {
            kind: objectKind,
            id: objectId,
            query: textInputRef.current
        }).catch((errors) => console.log('Error while loading search results', errors));
    };

    const handleDialogContentClick = (e) => {
        const clickable = e.target.closest('[data-kind][data-id]');
        if (clickable) {
            const objectKind = clickable.getAttribute('data-kind');
            const objectId = clickable.getAttribute('data-id');
            storeChosenSearchResult(objectKind, objectId);
            popover.handleClose();
        }
    };

    const results = useMemo(() => {
        return {
            paths: pathHits?.slice(0, 8),
            ...searchResults
        };
    }, [pathHits, searchResults]);

    const flattenedResults = useMemo(() => {
        const flatList = [];
        Object.keys(results).forEach(key => {
            const res = results[key];
            if (Array.isArray(res) && res.length > 0) {
                res.forEach(item => {
                    flatList.push({kind: key, data: item});
                });
            }
        });
        return flatList;
    }, [results]);

    const scrollToItem = (index) => {
        if (flattenedResults[index]) {
            const {kind, data} = flattenedResults[index];
            const list = listRef.current;
            if (list) {
                const item = list.querySelector(
                    `[data-kind="${kind}"][data-id="${data?.id || data?.value || data?.path}"]`
                );
                if (item) {
                    item.scrollIntoView({block: 'nearest', behavior: 'smooth'});
                }
            }
        }
    };

    const handleKeyDown = useCallback((e) => {
        if (flattenedResults.length === 0) return;
        if (e.key === 'ArrowDown') {
            e.preventDefault();
            setSelectedIndex(prev => {
                const newIndex = (prev + 1) % flattenedResults.length;
                setCurrentlySelected(flattenedResults[newIndex]);
                scrollToItem(newIndex);
                return newIndex;
            });
        } else if (e.key === 'ArrowUp') {
            e.preventDefault();
            setSelectedIndex(prev => {
                const newIndex = (prev - 1 + flattenedResults.length) % flattenedResults.length;
                setCurrentlySelected(flattenedResults[newIndex]);
                scrollToItem(newIndex);
                return newIndex;
            });
        } else if (e.key === 'Enter') {
            e.preventDefault();
            popover.handleClose();
            if (currentlySelected?.data?.object_link) {
                handleNavigate(currentlySelected?.data?.object_link);
                storeChosenSearchResult(currentlySelected?.kind, currentlySelected?.data?.id);
            } else if (listRef.current) {
                const {kind, data} = currentlySelected;
                const item = listRef.current.querySelector(
                    `[data-kind="${kind}"][data-id="${data?.id || data?.value || data?.path}"]`
                );
                if (item) {
                    item.click();
                }
            }
        }
    }, [flattenedResults, currentlySelected, handleNavigate]);

    useEffect(() => {
        if (flattenedResults.length > 0) {
            setSelectedIndex(0);
            setCurrentlySelected(flattenedResults[0]);
            scrollToItem(0);
        } else {
            setSelectedIndex(-1);
            setCurrentlySelected(null);
        }
    }, [JSON.stringify(flattenedResults)]);

    useEffect(() => {
        if (listRef.current && Object.keys(results).length > 0) {
            listRef.current.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }, [JSON.stringify(results)]);

    useEffect(() => {
        const currentInput = inputEl.current;
        if (currentInput) {
            currentInput.addEventListener('keydown', handleKeyDown);
            return () => {
                currentInput.removeEventListener('keydown', handleKeyDown);
            };
        }
    }, [handleKeyDown]);

    useEffect(() => {
        if (popover.open && inputEl.current) {
            inputEl.current.focus();
        }
    }, [popover.open]);

    useEffect(() => {
        setLoading(true);
        debouncedChangeHandler();
    }, [body]);

    const hasAnyResults = useMemo(() => {
        return Object.values(results).some(arr => Array.isArray(arr) && arr.length > 0);
    }, [results]);

    return (
        <Dialog
            open={popover.open}
            onClose={popover.handleClose}
            maxWidth="sm"
            fullWidth={true}
            PaperProps={{sx: {borderRadius: '28px'}}}
            keepMounted={true}
            disableAutoFocus
            disableEnforceFocus
            TransitionProps={{
                onEnter: () => {
                    if (inputEl.current) {
                        inputEl.current.focus();
                        if (body) {
                            inputEl.current.select();
                        }
                    }
                }
            }}
        >
            <DialogContent
                sx={{p: 0}}
                onClick={handleDialogContentClick}
            >
                <Box sx={{p: 1}}>
                    <OutlinedInput
                        fullWidth
                        autoFocus={true}
                        inputRef={inputEl}
                        value={body}
                        onChange={handleChange}
                        sx={{height: 45, borderRadius: '25px', ...sx}}
                        placeholder={softwareName + ' ' + t('common.search')}
                        endAdornment={
                            <InputAdornment position="end">
                                <Stack direction="row" spacing={1} justifyContent="flex-end" alignItems="center">
                                    {/* Filters */}
                                    {activeSearchFilters?.sort((a, b) => a.localeCompare(b)).map(filter => (
                                        <Chip
                                            key={'filter-' + filter}
                                            onDelete={() => {
                                                toggleDisableFilter(filter);
                                                debouncedChangeHandler();
                                            }}
                                            color={disabledSearchFilters?.includes(filter) ? 'default' : 'primary'}
                                            deleteIcon={(
                                                <Tooltip
                                                    title={
                                                        disabledSearchFilters?.includes(filter)
                                                            ? t('common.activate')
                                                            : t('common.deactivate')
                                                    }
                                                >
                                                    {disabledSearchFilters?.includes(filter)
                                                        ? <OnIcon iconName="CheckCircle" />
                                                        : <OnIcon iconName="XCircle" />
                                                    }
                                                </Tooltip>
                                            )}
                                            label={filter}
                                        />
                                    ))}
                                    {/* ESC button if not searching */}
                                    <Button
                                        color="default"
                                        variant="contained"
                                        sx={{minWidth: 40, height: 25, fontSize: 12}}
                                        onClick={popover.handleClose}
                                    >
                                        esc
                                    </Button>
                                </Stack>
                            </InputAdornment>
                        }
                    />
                </Box>

                {/* If we have text typed, show either skeleton (if loading & no fallback)
                    or the real results. */}
                {body !== '' && (
                    <List
                        sx={{p: 0, maxHeight: 600, overflowY: 'auto'}}
                        ref={listRef}
                    >
                        {(isLoading && !hasAnyResults) ? (
                            // Display skeleton only when "search" is in flight
                            // and we don't have fallback data to show
                            <SearchResultsSkeleton />
                        ) : (
                            // Otherwise, show the results
                            <>
                                {hasAnyResults ? Object.keys(results).map(key => {
                                    const res = results[key];
                                    if (Array.isArray(res) && res.length > 0) {
                                        if (key === 'paths') {
                                            return (
                                                <React.Fragment key={key}>
                                                    <Divider sx={{mt: 2}}/>
                                                    {renderObjects('paths', res)}
                                                </React.Fragment>
                                            );
                                        } else {
                                            return (
                                                <React.Fragment key={key}>
                                                    <Divider sx={{my: 2}}/>
                                                    <ListItem>
                                                        <ListItemText
                                                            primary={t('core.search.' + key)}
                                                            primaryTypographyProps={{variant: 'overline'}}
                                                        />
                                                    </ListItem>
                                                    {renderObjects(key, res)}
                                                </React.Fragment>
                                            );
                                        }
                                    }
                                    return null;
                                }) : <NoSearchResults />}
                            </>
                        )}
                    </List>
                )}
            </DialogContent>
        </Dialog>
    );
}

SearchDialog.propTypes = {
    popover: PropTypes.object,
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    sx: PropTypes.object
};

export default SearchDialog;
