import React from "react";
import {Card, Chip, Link, ListItem, Stack, Typography} from "@mui/material";
import OverviewList from "../../../elements/overview-list";
import capitalizeFirstLetter from "../../../../utils/calitalize-first-letter";
import moment from "moment/moment";
import {useIsMobile} from "../../../../hooks/use-is-mobile";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import useApi from "../../../../hooks/use-api";
import {useNotifications} from "../../../../hooks/use-notifications";

function EmailAdminPanel(props){

    const {
        query = {state__not_in: ['sent', 'opened', 'clicked']},
        asCard = true,
        onRemove
    } = props;

    const { t } = useTranslation();
    const { del } = useApi();
    const { notifySuccess } = useNotifications();
    const { isMobile } = useIsMobile();

    const handleRemove = (id) => {
        if(window.confirm(t('notify.are_you_sure'))){
            del('core/emails', id).then(() => {
                notifySuccess(t);
                onRemove?.(id);
            })
        }
    }

    const emailColors = {
        failed: 'error',
        re_attempting: 'warning',
        sent: 'success',
    }

    const list = (
        <OverviewList
            endpoint='core/emails'
            withSearch={true}
            query={query}
            renderItem={(item, index, setItems) => (
                <ListItem
                    key={index}
                >
                    <Stack
                        sx={{width: '100%'}}
                        key={index}
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={2}
                    >
                        <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                        >
                            {/*{!isMobile && item?.icon}*/}
                            <Stack
                                direction="row"
                                spacing={2}
                                alignItems="center"
                            >
                                <Chip
                                    label={capitalizeFirstLetter(item?.state)}
                                    color={emailColors?.[item?.state] || 'default'}
                                    sx={{minWidth: 100}}
                                />
                                <Stack>
                                    <Stack direction="row" alignItems="center" spacing={2}>
                                        <Typography color={emailColors?.[item?.state] || 'text.primary'} variant="subtitle1">{item?.receiver}</Typography>
                                        <Link underline="hover" sx={{cursor: 'pointer'}} variant="caption" color="textSecondary" onClick={() => {
                                            handleRemove(item?.id);
                                            setItems(prev => prev.filter(i => i.id !== item?.id));
                                        }}>
                                            {t('common.remove')}
                                        </Link>
                                    </Stack>
                                    <Typography variant="caption" color="text.secondary">{item?.subject}</Typography>
                                </Stack>
                            </Stack>

                        </Stack>
                        <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="flex-end"
                            spacing={2}
                        >
                            {!isMobile && (
                                <Typography variant="overline" sx={{textAlign: 'right'}}>
                                    {moment(item?.created_at).fromNow()}
                                </Typography>
                            )}
                        </Stack>
                    </Stack>
                </ListItem>
            )}
        />
    )

    if(!asCard)
        return list;

    return (
        <Card>
            {list}
        </Card>
    )
}

EmailAdminPanel.propTypes = {
    query: PropTypes.object,
    asCard: PropTypes.bool,
}

export default EmailAdminPanel;