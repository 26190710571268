import React, {useEffect, useState} from 'react';
import PropTypes from "prop-types";
import useOmniaApi from "../../../../../hooks/use-omnia-api";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
    Button,
    Box,
    Tabs,
    Tab,
    Avatar,
    Chip,
    Stack,
    Divider,
    CircularProgress
} from "@mui/material";
import {useTranslation} from "react-i18next";
import SaveButton from "../../../../elements/save-button";
import PlatformAdAccountsTable from "../../integrations/platform-ad-accounts-table";
import useTabs from "../../../../../hooks/use-tabs";
import OnIcon from "../../../../elements/icon";
import {useTheme} from "@mui/system";
import IntegrationConfig from "./integration-config";
import {useNotifications} from "../../../../../hooks/use-notifications";
import {useIsMobile} from "../../../../../hooks/use-is-mobile";

const stateColorMap = {
    okay: 'success',
    about_to_expire: 'warning',
    unreachable: 'error',
    inaccessible: 'error',
    default: 'default',
    error: 'error',
    synchronizing: 'default'
}

function IntegrationDialog({open, onClose, integration}) {
    const {t} = useTranslation();
    const {del} = useOmniaApi();
    const theme = useTheme();
    const {notify, notifySuccess} = useNotifications();
    const { isMobile } = useIsMobile();
    const { tab, handleTabChange } = useTabs(null);
    const [settings, setSettings] = useState(integration);
    const [saving, setSaving] = useState(false);
    const { put } = useOmniaApi();

    const handleRemove = () => {
        if(window.confirm(t('notify.are_you_sure'))){
            const endpoint = integration?.integration_kind === 'token' ? 'core/token-integrations' : 'core/config-integrations';
            del(endpoint, integration).then(() => {
                onClose();
            });
        }
    };

    const handleSave = () => {
        setSaving(true);
        if(integration?.integration_kind === 'config'){
            put('core/config-integrations', settings).then(() => {
                notifySuccess(t);
                onClose?.();
            }).finally(() => {
                setSaving(false);
            })
        } else if(integration?.integration_kind === 'token'){
            put('core/token-integrations', settings).then(() => {
                notifySuccess(t);
                onClose?.();
            }).finally(() => {
                setSaving(false);
            })
        } else {
            notify(t('mgt.connectors.no_authenticator'), "error");
            setSaving(false);
        }
    }

    const selectedIntegration = integration?.connector?.integration_bases?.find(base => base === tab) || null;

    useEffect(() => {
        handleTabChange(null, integration?.connector?.integration_bases?.[0]);
    }, [integration?.connector?.integration_bases]);

    useEffect(() => {
        setSettings(integration);
    }, [integration]);

    if(!integration)
        return null;

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="md"
            fullScreen={isMobile}
            fullWidth={true}
        >
            <DialogTitle>
                <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{width: '100%'}}>
                    <Stack direction="row" spacing={2} alignItems="center">
                        <Avatar
                            alt='connector-logo'
                            src={integration?.connector?.logo_ui || null}
                        >
                            <OnIcon iconName={integration?.connector?.icon_ui || 'IntersectCircle'}/>
                        </Avatar>
                        <Stack direction="column">
                            <Typography variant="h4">
                                {integration.connector.name}
                            </Typography>
                            {integration?.name && (
                                <Typography variant="subtitle1">
                                    {integration?.name}
                                </Typography>
                            )}
                        </Stack>
                    </Stack>
                    {integration.state === 'synchronizing' ? (
                        <CircularProgress size={24} />
                    ) : (
                        <Chip
                            label={integration.state.toUpperCase()}
                            color={stateColorMap?.[integration.state] || 'default'}
                        />
                    )}
                </Stack>
            </DialogTitle>
            <DialogContent
                style={{
                    height: 600,
                    padding: 0,
                    paddingTop: 10,
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >

                <Tabs value={tab} sx={{px: 2}} onChange={handleTabChange}>
                    {integration?.connector?.integration_bases?.map((baseName, index) => (
                        <Tab label={baseName} value={baseName} key={index} />
                    ))}
                    <Tab label={t('common.settings')} value='IntegrationConfiguration' />
                    <Tab label={t('common.logs')} value='IntegrationLogs' disabled={true} />
                </Tabs>
                <Divider sx={{mb: 2}} />

                <Box sx={{flex: 1, overflow: 'hidden'}}>

                    {(tab === 'IntegrationConfiguration') ? (
                        <Box sx={{height: '100%', overflow: 'auto', padding: 2}} px={2}>
                            <IntegrationConfig
                                connector={integration?.connector}
                                settings={settings}
                                setSettings={setSettings}
                            />
                        </Box>
                    ) : (selectedIntegration === 'Ads Platform') ? (
                        <PlatformAdAccountsTable
                            noBorder={true}
                            noCard={true}
                            connectorId={integration?.connector?.id}
                            height='100%'
                        />
                    ) : (
                        <Box sx={{height: '100%', overflow: 'auto', padding: 2}} px={2}>
                            <Stack
                                direction="column"
                                spacing={1}
                                sx={{minHeight: 500}}
                                alignItems='center'
                                justifyContent="center"
                                color='textSecondary'
                            >
                                <OnIcon
                                    iconName="Sliders02"
                                    sx={{color: theme.palette.text.secondary}}
                                    size="large"
                                />
                                <Typography variant="h5" color="textSecondary">
                                    {t('mgt.connectors.no_integration_settings')}
                                </Typography>
                                <Typography variant="subtitle1" color="textSecondary">
                                    {t('mgt.connectors.no_integration_settings_info')}
                                </Typography>
                            </Stack>
                        </Box>
                    )}
                </Box>

            </DialogContent>
            <DialogActions>
                <Stack direction="row" justifyContent="space-between" sx={{width: '100%'}}>
                    <Stack direction="row" spacing={2}>
                        <Button variant="outlined" onClick={()=>{onClose()}}>
                            {t('common.cancel')}
                        </Button>
                        <Button variant="outlined" color="error" onClick={handleRemove}>
                            {t('common.remove')}
                        </Button>
                    </Stack>
                    <SaveButton
                        onClick={handleSave}
                        loading={saving}
                    />
                </Stack>
            </DialogActions>
        </Dialog>
    );
}

export default IntegrationDialog;

IntegrationDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    integration: PropTypes.object
}
