import React from "react";
import {Stack} from "@mui/material";
import ShortTermMemoryGroup from "./short-term-memory-group";
import PropTypes from "prop-types"

function ShortTermMemories({memories = [], ...rest}){

    const groupedMemories = memories.reduce((acc, item) => {
        const [kind] = item.reference_object.split('-');
        if (!acc[kind]) {
            acc[kind] = [];
        }
        acc[kind].push(item);
        return acc;
    }, {});

    const results = Object.entries(groupedMemories);

    return (
        <Stack direction="column" sx={{width: '100%'}}>
            {results?.map((memoryGroup, i) => (
                <ShortTermMemoryGroup
                    objectKind={memoryGroup?.[0]}
                    memories={memoryGroup?.[1]?.map(m => m.content)} key={'group-' + i}
                    {...rest}
                />
            ))}
        </Stack>
    )
}

ShortTermMemories.propTypes = {
    memories: PropTypes.array
}

export default ShortTermMemories;