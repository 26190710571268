import React from "react";
import {
    Box,
    Button,
    Dialog, DialogActions,
    DialogContent,
    DialogTitle, IconButton,
    MenuItem,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import {useDialog} from "../../../hooks/use-dialog";
import {useTranslation} from "react-i18next";
import {useFormik} from "formik";
import useOmniaApi from "../../../hooks/use-omnia-api";
import {useNotifications} from "../../../hooks/use-notifications";
import {FileDropzone} from "../../elements/file-dropzone";
import SaveButton from "../../elements/save-button";
import OnIcon from "../../elements/icon";
import {useIsMobile} from "../../../hooks/use-is-mobile";
import {useLocation} from "react-router-dom";
import useSidebarWidth from "../../../hooks/use-sidebar-width";

const BOTTOM_RIGHT_BLOCKED_URLS = [
    '/groon/analytics/datahub',
    '/groon/resources/orders',
]

const DISABLE_HELP_VIEWS = [
    '/groon/home/chat'
]

function CreateUserRequest(){

    const dialog = useDialog();
    const { post } = useOmniaApi();
    const { notify } = useNotifications();
    const { isMobile } = useIsMobile();
    const location = useLocation();
    const { t } = useTranslation();
    const sideBarWidth = useSidebarWidth();

    const formik = useFormik({
        initialValues: {
            kind: 'bug',
            description: '',
            files: []
        },
        onSubmit: (values, helpers) => {
            helpers.setSubmitting(true);
            post('core/tickets', values).then(() => {
                helpers.resetForm();
                dialog.handleClose();
                notify(t("common.thank_you"), "success");
            }).finally(() => {
                helpers.setSubmitting(false);
            });

        }
    })

    const labels = {
        feature: t("common.feature_request"),
        change: t("common.change_request"),
        bug: t("common.bug_report"),
        other: t("common.other")
    }

    const placeholder = {
        feature: t("common.feature_request_placeholder"),
        change: t("common.change_request_placeholder"),
        bug: t("common.bug_report_placeholder"),
        other: t("common.other_placeholder")
    }

    const openRequest = () => {
        dialog.handleOpen(null);
    }

    if(DISABLE_HELP_VIEWS.includes(location.pathname))
        return;

    return (
        <>
            <Dialog
                open={dialog.open}
                onClose={dialog.handleClose}
                fullWidth={true}
                fullScreen={isMobile}
                maxWidth={'sm'}
            >
                <DialogTitle>
                    <Typography variant="h4">
                        {t('dialogs.open_ticket.title')}
                    </Typography>
                    <Typography variant="subtitle1" sx={{mt: 2}}>
                        {t('dialogs.open_ticket.description')}
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Box mb={2} mt={2}>
                        <TextField
                            select
                            fullWidth
                            label={t("attributes.kind")}
                            name="kind"
                            onChange={formik.handleChange}
                            value={formik.values.kind}
                        >
                            <MenuItem value="feature">{labels['feature']}</MenuItem>
                            <MenuItem value="change">{labels['change']}</MenuItem>
                            <MenuItem value="bug">{labels['bug']}</MenuItem>
                            <MenuItem value="other">{labels['other']}</MenuItem>
                        </TextField>
                        <TextField
                            fullWidth
                            autoFocus={true}
                            label={labels[formik.values.kind]}
                            placeholder={placeholder[formik.values.kind]}
                            name="description"
                            onChange={formik.handleChange}
                            value={formik.values.description}
                            multiline
                            rows={4}
                            sx={{mt: 2}}
                        />
                    </Box>
                    <FileDropzone
                        height={200}
                        title={t("common.upload_files")}
                        subtitle={t("common.select_files_info")}
                        showButtons={false}
                        files={formik.values.files}
                        onDrop={(newFiles) => formik.setFieldValue('files', [...formik.values.files, ...newFiles])}
                        onRemove={(file) => formik.setFieldValue('files', formik.values.files.filter(f => f !== file))}
                    />
                </DialogContent>
                <DialogActions>
                    <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mt: 2, width: '100%' }}>
                        <Button onClick={dialog.handleClose} variant="outlined">{t('common.cancel')}</Button>
                        <SaveButton formik={formik} />
                    </Stack>
                </DialogActions>
            </Dialog>
            <IconButton
                sx={{
                    position: 'fixed',
                    bottom: 10,
                    zIndex: 1,
                    ...(BOTTOM_RIGHT_BLOCKED_URLS.includes(location.pathname) ? {left: sideBarWidth + 10} : {right: 10})
                }}
                onClick={openRequest}
            >
                <OnIcon iconName="HelpCircle" size="small" />
            </IconButton>
        </>
    )
}

export default CreateUserRequest;