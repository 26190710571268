import React, {useEffect, useState} from "react";
import OnlineIndicator from "../analytics/dashboards/online-indicator";
import {Stack, Typography} from "@mui/material";
import moment from "moment";
import PropTypes from "prop-types";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

function UserOnlineDot({userId = null, withText = false, dotOnly = false, ...rest}){

    // const account = useSelector(state => state.account.user);
    const {t} = useTranslation();
    const users = useSelector(state => state.users.users);
    const [user, setUser] = useState(null);

    useEffect(() => {
        if(users?.filter(u => u.id === userId)?.length > 0){
            setUser(users.filter(u => u.id === userId)[0]);
        } else {
            setUser(null);
        }
    }, [userId, user, users]);

    if(!user)
        return null;

    return (
        <Stack direction="row" spacing={1} alignItems="center">
            {dotOnly ? (
                <>
                    <OnlineIndicator size="small" status={user?.status || 'offline'} {...rest} />
                </>
            ) : (
                <>
                    {(user?.status || 'offline') !== 'offline' ? (
                        <>
                            <OnlineIndicator size="small" status={user?.status || 'offline'} {...rest} />
                            {withText && (
                                <Typography variant="caption" color="textSecondary" style={{marginLeft: 6}} {...rest}>
                                    {t('common.' + (user?.status || 'offline'))}
                                </Typography>
                            )}
                        </>
                    ) : (
                        <Typography color="textSecondary" noWrap variant="caption" {...rest}>
                            {(user.last_active === null) ? t('common.new') : moment(user.last_active).fromNow()}
                        </Typography>
                    )}
                </>
            )}
        </Stack>
    )
}

UserOnlineDot.propTypes = {
    userId: PropTypes.number.isRequired,
    withText: PropTypes.bool,
    dotOnly: PropTypes.bool,
};

export default UserOnlineDot;