import React, { useRef, useState } from "react";
import {
    Box,
    Divider,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Popover,
    Stack,
    SvgIcon,
    Typography,
} from "@mui/material";
import OnIcon from "../../../elements/icon";
import usePopover from "../../../../hooks/use-popover";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import useOmniaApi from "../../../../hooks/use-omnia-api";
import { updateMe } from "../../../../store/actions/account-actions";
import ClickableInput from "../../../elements/clickable-input";
import moment from "moment/moment";
import {useIsMobile} from "../../../../hooks/use-is-mobile";
import {useTheme} from "@mui/system";

function setUntilToday2359() {
    const now = new Date();
    now.setHours(23, 59, 0, 0);
    return now;
}

function setUntilThisSunday2359() {
    const now = new Date();
    // getDay(): 0=Sunday, 6=Saturday
    const day = now.getDay();
    const diff = day === 0 ? 0 : 7 - day;
    now.setDate(now.getDate() + diff);
    now.setHours(23, 59, 0, 0);
    return now;
}

function UserMuteNotifications() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { post } = useOmniaApi();
    const { isFlying } = useIsMobile();
    const theme = useTheme();
    const user = useSelector((state) => state.account.user);
    const isMuted = user?.mute_until && new Date(user.mute_until).getTime() > Date.now();
    const popover = usePopover();
    const anchorRef = useRef(null);
    const now = moment();
    const muteUntil = moment(user?.mute_until);

    // Find out a good notation
    let untilText;
    if (muteUntil.isSame(now, 'day')) {
        untilText = muteUntil.format('HH:mm'); // e.g., "19:23"
    } else if (muteUntil.isBefore(now.clone().endOf('week'))) {
        untilText = muteUntil.format('dddd'); // e.g., "Sunday"
    } else {
        untilText = muteUntil.format('DD.MM.'); // e.g., "12.03."
    }

    const suggestions = [
        {
            label: t("common.30m"),
            value: "30m",
            compute: () => {
                const d = new Date();
                d.setMinutes(d.getMinutes() + 30);
                return d;
            },
        },
        {
            label: t("common.1h"),
            value: "1h",
            compute: () => {
                const d = new Date();
                d.setHours(d.getHours() + 1);
                return d;
            },
        },
        {
            label: t("common.4h"),
            value: "4h",
            compute: () => {
                const d = new Date();
                d.setHours(d.getHours() + 4);
                return d;
            },
        },
        {
            label: t("common.today"),
            value: "today",
            compute: setUntilToday2359,
        },
        {
            label: t("common.this_week"),
            value: "week",
            compute: setUntilThisSunday2359,
        },
    ];

    const handleSetMuteUntil = (date) => {
        post("core/me/update_data", {...user, mute_until: date,}).then(data => {
            dispatch(updateMe(data));
        })
    };

    const handleReset = () => {
        handleSetMuteUntil(null);
        popover.handleClose();
    }

    const handleSuggestionClick = async (sug) => {
        // Predefined time: compute date, post, close
        const chosenDate = sug.compute();
        popover.handleClose();
        handleSetMuteUntil(chosenDate);
    };

    return (
        <>
            <ListItemButton
                ref={anchorRef}
                onClick={popover.handleOpen}
                sx={{
                    borderRadius: 1,
                    mt: 1,
                    px: 1,
                    py: 0.5,
                }}
            >
                <ListItemIcon>
                    <SvgIcon fontSize="small">
                        <OnIcon iconName="VolumeX" />
                    </SvgIcon>
                </ListItemIcon>

                {/* Show "on" in textSecondary on the right side if user is muted */}
                <ListItemText
                    primary={
                        <Box display="flex" alignItems="center" width="100%">
                            <Typography variant="body1">
                                {t("core.mute_notifications")}
                            </Typography>
                            {isMuted && (
                                <Typography variant="body2" color="textSecondary" sx={{ ml: "auto" }}>
                                    {t("common.until") + ' ' + untilText}
                                </Typography>
                            )}
                        </Box>
                    }
                />
            </ListItemButton>

            <Popover
                open={popover.open}
                anchorEl={anchorRef.current}
                onClose={popover.handleClose}
                anchorOrigin={{ vertical: "top", horizontal: "left" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                slotProps={{
                    paper: {
                        sx: {borderRadius: theme?.config?.card_radius + "px"},
                        style: {transform: isFlying ? 'translateX(-15px)' : 'translateY(10px)'}
                    },
                }}
            >
                <Box sx={{ width: 150 }}>
                    <Stack py={1}>
                        {suggestions.map((sug) => (
                            <Box my={0.5} px={1} key={sug.value}>
                                <ClickableInput
                                    onClick={() => handleSuggestionClick(sug)}
                                    sx={{
                                        border: "none",
                                        borderWidth: 0,
                                        py: 0.5,
                                        px: 2,
                                    }}
                                >
                                    <Typography variant="body1" textAlign="left">
                                        {sug.label}
                                    </Typography>
                                </ClickableInput>
                            </Box>
                        ))}
                        {isMuted && (
                            <>
                                <Divider />
                                <Box px={1} my={1}>
                                    <ClickableInput
                                        onClick={handleReset}
                                        sx={{
                                            border: "none",
                                            borderWidth: 0,
                                            py: 0.5,
                                            px: 2,
                                        }}
                                    >
                                        <Typography variant="body1" color="error" textAlign="left">
                                            {t("core.unmute")}
                                        </Typography>
                                    </ClickableInput>
                                </Box>
                            </>
                        )}
                    </Stack>
                </Box>
            </Popover>
        </>
    );
}

export default UserMuteNotifications;
