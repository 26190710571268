import {useMemo, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from "react-redux";
import {useSecurityCheck} from "./hooks/use-security-check";
import {useAppMode} from "./hooks/use-app-mode";

export const useSections = () => {
    /**
     *
     * TODO: this component can be improved!! When e.g. switching between admin and rest, the menu is for a millisecond
     * empty. This is because the useSections is loaded in the Layout component and should instead be moved up!
     *
     */

    const { t } = useTranslation();
    const appMode = useAppMode();
    const { hasRights, isModuleInstalled } = useSecurityCheck();
    const unread = useSelector((state) => state.messages.unread);
    const user = useSelector((state) => state.account.user);
    const cmsEnabled = useSelector(state => state.cms.enabled);
    const isHome = appMode.currentApp?.path?.includes('/groon/home');
    const unreadRef = useRef();
    unreadRef.current = unread;

    const processItem = (item) => {

        if(!item)
            return null;

        // Translate the title
        if (item.title) {
            item.title = t(item.title);
        }

        // Check if this should be shown FIXME: I think this can be removed
        if (item?.showIf){
            if(item?.showIf === 'has_tickets'){
                if(user?.open_requests === 0){
                    return null;
                }
            }
            if(item?.showIf === 'cms_enabled'){
                if(!cmsEnabled){
                    return null;
                }
            }
        }

        // If the item has nested items, process them recursively
        if (item.items) {
            item.items = item.items.map(processItem).filter(Boolean);
        }

        // Check permissions if they exist, return null to filter out if checkRights fails
        if (!(!item.permissions || hasRights(item.permissions, 'or'))) {
            return null;
        }

        // Check if module is installed
        if (!(!item.module || isModuleInstalled(item.module))) {
            return null;
        }

        return item;
    };

    const getProcessedNavigation = (menuData) => {

        const clonedMenuData = structuredClone(menuData);

        return clonedMenuData.map(section => {
            // Translate the section titles if present
            if (section.title) {
                section.title = t(section.title);
            }

            // Process each item within the section
            if (section.items) {
                section.items = section.items.map(processItem).filter(Boolean);
            }

            return section;
        }).filter(section => (!section?.module || isModuleInstalled(section?.module)) && hasRights(section?.permissions || []));

    };

    return useMemo(() => {

        // TODO: We need to have a common section on top and than the app I think (with exception of spaces)
        let sections;

        // Check if we are in a space (then, different rules apply)
        if(isHome){
            sections = appMode.currentApp?.items || [];
        } else {
            // Obtain the currently active items
            const items = appMode.currentApp?.items || [];

            // Final sections
            sections = [
                {
                    title: 'common-top',
                    items: [
                        {
                            title: 'common.search',
                            path: null,
                            superCustomAction: 'search',
                            icon: 'SearchMd',
                        },
                        {
                            title: 'layout.home.title',
                            path: '/groon/home',
                            icon: 'Home01',
                            superCustomAction: 'to-home',
                        },
                    ]
                },
                ...items
            ]
        }

        // Return the processed navigation items
        return getProcessedNavigation(sections);

    }, [isHome, appMode.currentApp]);

};

export const coreAppsConfig = {
    home: {
        title: 'layout.home.title',
        icon: 'Home01',
        path: '/groon/home',
        items: [
            {
                title: 'layout.home.title',
                path: '/groon/home',
                icon: 'Home01',
                tooltip: 'layout.tooltips.home.home'
            },
            {
                title: 'layout.home.calendar',
                path: '/groon/home/calendar',
                icon: 'Calendar',
                permissions: ['calendar'],
                tooltip: 'layout.tooltips.home.calendar'
            },
            {
                title: 'layout.home.files',
                path: '/groon/home/files',
                icon: 'File02',
                permissions: ['files'],
                tooltip: 'layout.tooltips.home.files'
            },
            {
                title: 'layout.home.tasks',
                path: '/groon/home/tasks',
                icon: 'CheckCircle',
                permissions: ['tasks'],
                tooltip: 'layout.tooltips.home.tasks'
            },
            {
                title: 'layout.home.chats',
                path: '/groon/home/chat',
                permissions: ['social_intranet'],
                icon: 'Inbox01',
                tooltip: 'layout.tooltips.home.chats'
            },
            {
                title: "intelligence.history",
                path: null,
                superCustomAction: 'extend-intelligence-chats',
                icon: 'ClockRewind',
            },
        ]
    },
    connections: {
        title: "layout.connections.title",
        path: '/groon/connections',
        icon: 'Users01',
        permissions: ['connections'],
        module: 'connections',
        items: [
            {
                title: 'layout.connections.title',
                items: [
                    {
                        title: "common.overview",
                        icon: 'BarChartSquare02',
                        path: '/groon/connections/dashboard',
                        tooltip: 'layout.tooltips.connections.index'
                    },
                    {
                        title: "layout.connections.graph",
                        icon: 'Share07',
                        path: '/groon/connections/graph',
                        tooltip: 'layout.tooltips.connections.graph'
                    },
                    {
                        title: "common.emails",
                        icon: 'Mail01',
                        path: '/groon/connections/emails',
                        disabled: true,
                        tooltip: 'layout.tooltips.connections.index'
                    },
                ]
            },
            {
                title: 'layout.connections.contacts',
                items: [
                    {
                        title: "layout.connections.customers",
                        icon: 'Building07',
                        permissions: ['contacts'],
                        path: '/groon/connections/customers',
                        tooltip: 'layout.tooltips.connections.contacts'
                    },
                    {
                        title: "layout.connections.contacts",
                        icon: 'UserCircle',
                        permissions: ['contacts'],
                        path: '/groon/connections/contacts',
                        tooltip: 'layout.tooltips.connections.contacts'
                    },
                    {
                        title: "layout.connections.partners",
                        icon: 'LifeBuoy02',
                        permissions: ['contacts'],
                        path: '/groon/connections/partners',
                        tooltip: 'layout.tooltips.connections.contacts'
                    },
                    {
                        title: "layout.connections.supplier",
                        icon: 'Truck02',
                        permissions: ['contacts'],
                        path: '/groon/connections/supplier',
                        tooltip: 'layout.tooltips.connections.contacts'
                    },
                    {
                        title: "layout.connections.leads",
                        icon: 'UserUp01',
                        permissions: ['connection_leads'],
                        path: '/groon/connections/leads',
                        tooltip: 'layout.tooltips.connections.leads'
                    },
                ]
            },
            {
                title: "layout.connections.contexts",
                permissions: ['crm_contexts'],
                superCustomAction: 'contexts-list'
            },
        ]
    },
    marketing: {
        title: "layout.marketing.title",
        path: '/groon/marketing',
        icon: 'Announcement01',
        permissions: ['marketing'],
        module: 'marketing',
        items: [
            {
                title: 'layout.marketing.title',
                items: [
                    {
                        title: "common.overview",
                        icon: 'BarChartSquare02',
                        permissions: ['marketing'],
                        path: '/groon/marketing',
                        tooltip: 'layout.tooltips.marketing.index'
                    },
                    {
                        title: "layout.marketing.platforms",
                        icon: 'LayersTwo01',
                        permissions: ['performance_marketing'],
                        path: '/groon/marketing/platforms',
                        tooltip: 'layout.tooltips.marketing.platforms'
                    },
                    {
                        title: "marketing.projects",
                        icon: 'Folder',
                        permissions: ['contents_module_management'],
                        disabled: true,
                        path: '/groon/marketing/projects',
                        tooltip: 'layout.tooltips.marketing.projects'
                    },
                    {
                        title: "layout.marketing.competitors",
                        icon: 'UserX01',
                        permissions: ['contents_module_management'],
                        disabled: true,
                        path: '/groon/marketing/competitors',
                        tooltip: 'layout.tooltips.marketing.competitors'
                    },
                ]
            },
            {
                title: 'marketing.online_presences.social_media',
                items: [
                    {
                        title: 'layout.marketing.publishing',
                        icon: 'PlayCircle',
                        permissions: ["publishing"],
                        disabled: true,
                        path: '/groon/marketing/publishing',
                        tooltip: 'layout.tooltips.marketing.publishing'
                    },
                    {
                        title: 'layout.marketing.online_presences',
                        icon: 'Signal02',
                        permissions: ['publishing'],
                        disabled: true,
                        path: '/groon/marketing/channels',
                        tooltip: 'layout.tooltips.marketing.online_presences'
                    },
                ]
            },
            {
                title: 'layout.marketing.performance_marketing',
                items: [
                    {
                        title: "layout.marketing.ad_accounts",
                        icon: 'LogIn04',
                        permissions: ['performance_marketing'],
                        path: '/groon/marketing/ad-accounts',
                        tooltip: 'layout.tooltips.marketing.ad_accounts'
                    },
                    {
                        title: "layout.marketing.campaigns",
                        superCustomAction: 'campaigns-list'
                    },
                ]
            },
        ]
    },
    resources: {
        title: "layout.resources.title",
        path: '/groon/resources',
        icon: 'Atom02',
        permissions: ['erp'],
        module: 'resources',
        items: [
            {
                title: 'layout.resources.title',
                items: [
                    {
                        title: 'layout.resources.financial_overview',
                        permissions: ['erp_financial_transactions'],
                        icon: 'BarChartSquare02',
                        path: '/groon/resources/financial-accounting',
                        tooltip: 'layout.tooltips.resources.index'
                    },
                    {
                        title: 'layout.resources.time_tracking',
                        permissions: ['erp_allow_time_booking'],
                        icon: 'ClockCheck',
                        path: '/groon/resources/time-tracking',
                        tooltip: 'layout.tooltips.resources.index'
                    },
                    {
                        title: 'layout.resources.transactions',
                        permissions: ['erp_financial_transactions', 'erp_warehouse_management_transactions', 'erp_allow_time_booking'],
                        icon: 'SwitchHorizontal01',
                        path: '/groon/resources/transactions',
                        tooltip: 'layout.tooltips.resources.transactions'
                    },
                    {
                        title: 'layout.resources.planning',
                        icon: 'ChartBreakoutCircle',
                        disabled: true,
                        path: '/groon/resources/planning',
                        tooltip: 'layout.tooltips.resources.planning'
                    },
                ]
            },
            {
                title: 'layout.resources.sales',
                items: [
                    {
                        title: "layout.resources.products_services_short",
                        permissions: ['tms_services_products'],
                        icon: 'Package',
                        path: '/groon/resources/products',
                        tooltip: 'layout.tooltips.resources.products'
                    },
                    {
                        title: "layout.resources.offers",
                        permissions: ['tms_offers'],
                        icon: 'FilePlus02',
                        path: '/groon/resources/offers',
                        tooltip: 'layout.tooltips.resources.offers'
                    },
                    {
                        title: "layout.resources.orders",
                        permissions: ['tms_orders'],
                        icon: 'ReceiptCheck',
                        path: '/groon/resources/orders',
                        tooltip: 'layout.tooltips.resources.orders'
                    },
                    {
                        title: "layout.resources.subscriptions",
                        permissions: ['tms_subscriptions'],
                        icon: 'RefreshCcw05',
                        path: '/groon/resources/subscriptions',
                        tooltip: 'layout.tooltips.resources.subscriptions'
                    },
                    {
                        title: "layout.resources.invoices",
                        permissions: ['tms_invoices'],
                        icon: 'CurrencyEuroCircle',
                        path: '/groon/resources/invoices',
                        tooltip: 'layout.tooltips.resources.invoices'
                    }
                ]
            },
            {
                title: 'layout.resources.warehouse',
                items: [
                    {
                        title: 'common.overview',
                        permissions: ['erp_warehouse_management_transactions'],
                        icon: 'BarChartSquare02',
                        path: '/groon/resources/warehouse-management',
                        tooltip: 'layout.tooltips.resources.index'
                    },
                    {
                        title: "layout.resources.inventory",
                        permissions: ['tms_services_products'],
                        icon: 'Database01',
                        path: '/groon/resources/warehouse-management/inventory',
                        tooltip: 'layout.tooltips.resources.products'
                    },
                    {
                        title: "layout.resources.warehouses",
                        permissions: ['tms_services_products'],
                        icon: 'Building06',
                        disabled: true,
                        path: '/groon/resources/warehouse-management/warehouses',
                        tooltip: 'layout.tooltips.resources.products'
                    },
                ]
            },
            {
                title: 'layout.resources.procurement',
                items: [
                    {
                        title: "layout.resources.negotiation",
                        permissions: ['tms_offers'],
                        icon: 'AnnotationDots',
                        disabled: true,
                        path: '/groon/resources/negotiation',
                        tooltip: 'layout.tooltips.resources.offers'
                    },
                    {
                        title: "layout.resources.inbound_orders",
                        permissions: ['tms_orders'],
                        icon: 'Package',
                        disabled: true,
                        path: '/groon/resources/inbound-orders',
                        tooltip: 'layout.tooltips.resources.orders'
                    },
                    {
                        title: "layout.resources.contracts",
                        permissions: ['tms_subscriptions'],
                        icon: 'File05',
                        disabled: true,
                        path: '/groon/resources/contracts',
                        tooltip: 'layout.tooltips.resources.subscriptions'
                    },
                ]
            }
        ]
    },
    spaces: {
        title: "layout.projects.title",
        path: '/groon/spaces',
        icon: 'Headphones02',
        permissions: ['projects'],
        module: 'projects',
        items: [
            {
                title: 'layout.projects.title',
                items: [
                    {
                        title: "common.overview",
                        icon: 'Grid01',
                        path: '/groon/spaces/list',
                        tooltip: 'layout.tooltips.projects.index'
                    },
                    {
                        title: 'layout.projects.title',
                        superCustomAction: 'spaces-list'
                    },
                    // {
                    //     title: "layout.projects.browse",
                    //     icon: 'SearchMd',
                    //     path: '/groon/spaces/browse',
                    //     tooltip: 'layout.tooltips.projects.browse'
                    // },
                ]
            },

        ]
    },
    analytics: {
        title: "layout.analytics.title",
        path: '/groon/analytics/datahub',
        permissions: ['groon_analytics'],
        icon: 'BarLineChart',
        items: [
            {
                title: 'layout.analytics.title',
                items: [
                    // FIXME: For now, this is an ugly view that nobody needs
                    // {
                    //     title: 'layout.analytics.dashboards',
                    //     icon: 'PieChart01',
                    //     path: '/groon/analytics/dashboards',
                    //     tooltip: 'layout.tooltips.analytics.dashboards'
                    // },
                    {
                        title: 'layout.analytics.data_hub',
                        icon: 'Data',
                        path: '/groon/analytics/datahub',
                        tooltip: 'layout.tooltips.analytics.data_hub'
                    },
                    {
                        title: 'layout.intelligence.datasets',
                        permissions: ['manage_datasets'],
                        icon: 'PresentationChart01',
                        path: '/groon/analytics/datasets',
                        tooltip: 'layout.tooltips.analytics.datasets'
                    },
                    {
                        title: "layout.my_links",
                        path: '/groon/analytics/short-links',
                        icon: 'Share01',
                        tooltip: 'layout.tooltips.analytics.short_links'
                    },
                ]
            },
            {
                title: 'layout.analytics.dashboards',
                superCustomAction: 'dashboard-list'
            }
        ]
    },
    workflows: {
        title: "layout.workflows.title",
        path: "/groon/workflows",
        icon: "Route",
        permissions: ['process_automation'],
        // module: 'core',
        items: [
            {
                title: 'Workflows',
                items: [
                    {
                        title: 'layout.workflows.title',
                        icon: 'Route',
                        permissions: ['process_automation'],
                        path: '/groon/workflows',
                        tooltip: 'layout.tooltips.workflows.index'
                    },
                ]
            }
        ]
    },
    website: {
        title: "layout.website.title",
        path: "/groon/website",
        icon: "Globe04",
        permissions: ['contents_module_management'],
        // module: 'marketing',
        items: [
            {
                title: 'layout.website.title',
                items: [
                    {
                        title: 'layout.website.title',
                        icon: 'PieChart01',
                        permissions: ['contents_module_management'],
                        path: '/groon/website',
                        tooltip: 'layout.tooltips.website.index'
                    },
                    {
                        title: 'layout.website.articles',
                        icon: 'File05',
                        permissions: ['contents_module_management'],
                        path: '/groon/website/articles',
                        tooltip: 'layout.tooltips.website.articles'
                    },
                    {
                        title: 'layout.website.sessions',
                        icon: 'Cursor01',
                        permissions: ['contents_module_management'],
                        path: '/groon/website/sessions',
                        tooltip: 'layout.tooltips.website.sessions'
                    },
                    {
                        title: 'layout.website.visitors',
                        icon: 'Users01',
                        permissions: ['contents_module_management'],
                        path: '/groon/website/visitors',
                        tooltip: 'layout.tooltips.website.visitors'
                    },
                    {
                        title: 'layout.website.configuration',
                        icon: 'Settings01',
                        permissions: ['contents_module_management'],
                        path: '/groon/website/configuration',
                        tooltip: 'layout.tooltips.website.configuration'
                    },
                ]
            },
            {
                title: 'layout.website.sites',
                superCustomAction: 'sites-list'
            }

        ]
    },
    aiStudio: {
        title: 'layout.intelligence.title',
        icon: 'Star06',
        path: '/groon/ai-studio',
        permissions: ['intelligence'],
        items: [
            {
                title: 'layout.intelligence.studio',
                items: [
                    {
                        title: 'layout.intelligence.playground',
                        path: '/groon/ai-studio',
                        icon: 'Beaker02',
                        permissions: ["intelligence"],
                        tooltip: 'layout.tooltips.intelligence.playground'
                    },
                    {
                        title: 'Trainings',
                        path: '/groon/ai-studio/trainings',
                        icon: 'LineChartDown05',
                        permissions: ["ai_models_training"],
                        tooltip: 'layout.tooltips.intelligence.trainings'
                    },
                ]
            },
            {
                title: 'Models',
                path: '/groon/ai-studio/models',
                icon: 'Star06',
                superCustomAction: 'ai-model-list',
                permissions: ["ai_models_training"],
                tooltip: 'layout.tooltips.intelligence.models'
            },
        ]
    },
    controlCenter: {
        title: 'layout.control_center.title',
        icon: 'Speedometer03',
        path: '/groon/control-center',
        permissions: ['control_center'],
        items: [
            {
                title: 'mgt.index.monitoring',
                items: [
                    {
                        title: 'common.overview',
                        path: '/groon/control-center',
                        icon: 'BarChartCircle01',
                        permissions: ["control_center"],
                        tooltip: 'mgt.index.monitoring'
                    },
                    {
                        title: 'mgt.index.security',
                        path: '/groon/control-center/security',
                        icon: 'Fingerprint01',
                        permissions: ["omnia_security"],
                        tooltip: 'mgt.index.security'
                    },
                    {
                        title: 'mgt.index.permissions',
                        path: '/groon/control-center/permissions',
                        icon: 'Shield01',
                        permissions: ["omnia_rights"],
                        tooltip: 'mgt.index.permissions'
                    },
                    {
                        title: 'mgt.index.connectors',
                        path: '/groon/control-center/connectors',
                        icon: 'Wifi',
                        permissions: ["omnia_connectors"],
                        tooltip: 'mgt.index.connectors'
                    },
                ]
            },
            {
                title: 'mgt.index.system_architecture',
                items: [
                    {
                        title: 'mgt.index.architecture',
                        path: '/groon/control-center/architecture',
                        icon: 'Server01',
                        permissions: ["control_center"],
                        tooltip: 'mgt.index.monitoring'
                    },
                    {
                        title: 'mgt.index.tasks',
                        path: '/groon/control-center/tasks',
                        icon: 'Speedometer03',
                        permissions: ["omnia_workers"],
                        tooltip: 'mgt.index.tasks'
                    },
                    {
                        title: 'mgt.index.jobs',
                        path: '/groon/control-center/jobs',
                        icon: 'Dataflow01',
                        permissions: ["omnia_tasks"],
                        tooltip: 'mgt.index.jobs'
                    },
                    {
                        title: 'mgt.index.ai_engine',
                        path: '/groon/control-center/ai-engine',
                        icon: 'Star06',
                        permissions: ["omnia_tasks"],
                        tooltip: 'mgt.index.ai_engine'
                    },
                ]
            },
            {
                title: 'mgt.index.ticket_system',
                items: [
                    {
                        title: 'mgt.index.ticket_system',
                        path: '/groon/control-center/service-management',
                        icon: 'Ticket02',
                        permissions: ["service_management"],
                        tooltip: 'mgt.index.ticket_system'
                    },
                ]
            },
        ]
    },
}
